import React, { useState, useEffect } from "react";
import {
  Button,
  Descriptions,
  Modal,
  Typography,
  Space,
  Skeleton,
  message,
  Tooltip,
} from "antd";
import SuspendIcon from "../../../assets/SuspendIcon.svg";
import { useSelector } from "react-redux";
import sdk from "../../../sdk/Accounts";
import { EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export default function CompanyDetails() {
  const [acct, setAcct] = useState(null);
  const [companyName, setCompanyName] = useState([]);
  const { groups, accountDetails } = useSelector((state) => state.loginReducer);
  const [isCompanySuspendModelOpen, setIsCompanysuspendModelOpen] =
    useState(false);
  const [companySuspendLoading, setCompanySuspendLoading] = useState(false);
  const history = useHistory();

  // Function to handle account details
  const fetchAccountDetails = async () => {
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      setAcct(account);
    }
  };

  const setCompnaysData = () => {
    let data = [];
    if (
      accountDetails?.segment_comapany !== null &&
      accountDetails?.segment_comapany !== undefined
    ) {
      JSON.parse(accountDetails?.segment_comapany).map((item) => {
        data.push(item?.company_name);
        return data;
      });
    }

    setCompanyName(data);
  };
  useEffect(() => {
    fetchAccountDetails();
    setCompnaysData();

    // eslint-disable-next-line
  }, []);

  const handleCompanySuspend = async () => {
    if (acct) {
      setCompanySuspendLoading(true);
      let companyId = acct?.id;
      let suspendFlag = acct?.disabled === true ? false : true;
      let result = await sdk.companySuspension(companyId, suspendFlag);
      if (result.error) {
        message.error(result.error, 5);
      } else {
        message.success(
          suspendFlag
            ? "Company suspended successfully"
            : "Company unsuspended successfully",
          5
        );
      }
    }
    setIsCompanysuspendModelOpen(false);
    setCompanySuspendLoading(false);
  };

  return (
    <>
      <div className="company-detail" style={{ paddingTop: "15px" }}>
        <span
          style={{ fontWeight: 600, fontSize: 18, fontFamily: "Matter-bold" }}
        >
          Company Information
        </span>
        {acct ? (
          <>
            <Descriptions bordered column={1} style={{ paddingTop: 14 }}>
              <Descriptions.Item
                labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                label="Company Name"
              >
                <span style={{ fontSize: "18px", color: "#727272" }}>
                  {acct && acct.company ? acct.company : "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                label="Company URL"
              >
                {acct ? (
                  <a
                    style={{ fontSize: "18px", color: "#727272" }}
                    href={acct.url}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {acct.url}
                  </a>
                ) : (
                  "-"
                )}
              </Descriptions.Item>
              {/*For Future use */}
              {/* <Descriptions.Item
                labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                label="Company Segment"
              >
                <span style={{ fontSize: "18px", color: "#727272" }}>
                  {acct && acct.company ? accountDetails?.category : "-"}
                </span>
                <Tooltip
                  placement="topLeft"
                  title="Please edit here"
                  style={{ fontWeight: "bold" }}
                >
                  <EditOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: 15,
                      color: "#727272",
                      marginLeft: 16,
                    }}
                    onClick={() => history.push("/details")}
                  />
                </Tooltip>
              </Descriptions.Item> */}
              <Descriptions.Item
                labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                label="Companies"
              >
                <span style={{ fontSize: "18px", color: "#727272" }}>
                  {acct && acct?.segment_comapany
                    ? companyName
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "-"}
                </span>
                <Tooltip
                  placement="topLeft"
                  title="Please edit here"
                  style={{ fontWeight: "bold" }}
                >
                  <EditOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: 15,
                      color: "#727272",
                      marginLeft: 16,
                    }}
                    onClick={() => history.push("/details")}
                  />
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                label="Account ID"
              >
                <span style={{ fontSize: "18px", color: "#727272" }}>
                  {acct ? (
                    <Typography.Text copyable={{ text: acct.id }}>
                      {acct.id}
                    </Typography.Text>
                  ) : (
                    "-"
                  )}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : (
          <>
            <Space style={{ width: "100%" }} direction="vertical">
              <Skeleton.Input active style={{ width: "60vw" }} size="large" />
              <Skeleton.Input active style={{ width: "60vw" }} size="large" />
              <Skeleton.Input active style={{ width: "60vw" }} size="large" />
              <Skeleton.Button
                active
                size="large"
                shape="round"
                style={{ width: 200, borderRadius: 7 }}
              />
            </Space>
          </>
        )}
        <Modal
          visible={isCompanySuspendModelOpen}
          closable={false}
          centered
          width={430}
          footer={[]}
          style={{ borderRadius: "20px" }}
          className="suspend-company-footer"
        >
          <div className="delete-modal-body">
            <img
              src={SuspendIcon}
              alt="suspend company"
              style={{ margin: "0 auto", display: "flex" }}
            />

            <Typography.Text
              style={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "center",
                paddingTop: "15px",
                fontWeight: 600,
              }}
            >
              {acct?.disabled === true
                ? "Are you sure you want to unsuspend the company?"
                : "Are you sure you want to suspend the company?"}
            </Typography.Text>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "12px",
              }}
            >
              <Button
                loading={companySuspendLoading}
                style={{
                  color: "black",
                  marginRight: 10,
                  padding: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 600,
                }}
                onClick={() => setIsCompanysuspendModelOpen(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleCompanySuspend();
                }}
                type="primary"
                style={{
                  background: "#416B88",
                  padding: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 600,
                }}
              >
                Yes, sure
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
