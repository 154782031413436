import { Row, Col, Input, Form, Button } from "antd";
import React from "react";
import Index from "../Login/background";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import { useHistory } from "react-router-dom";
import Footer from "../../../common/footer";

export default function ResetPassword() {
  let history = useHistory();

  function handleLogin() {
    history.push("/signin");
  }
  return (
    <Row justify="center">
      <Index />
      <Col span={12} md={12} lg={12} xs={24}>
        <Row
          justify="center"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          <Col span={12} style={{ textAlign: "center" }}>
            <img src={Xaaslogo} alt="" />
            <h4
              style={{
                color: "#416B88",
                textAlign: "center",
                fontWeight: "800",
                margin: "10px",
              }}
            >
              Reset your password
            </h4>
            <Form layout="vertical" name="normal_login" className="login-form">
              <Form.Item label="Enter new password">
                <Input.Password
                  value="mynewpassword"
                  style={{ borderRadius: "8px", height: "35px" }}
                />
              </Form.Item>
              <Form.Item label="Re-enter password">
                <Input.Password
                  value="mynewpassword"
                  style={{ borderRadius: "8px", height: "35px" }}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Button type="link" onClick={handleLogin}>
                      <span style={{ fontWeight: 600, fontSize: "15px" }}>
                        Login
                      </span>
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      style={{
                        background: "#416B88",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        height: "44px",
                        marginBottom: 0,
                        padding: 10,
                      }}
                    >
                      <span>Reset Password</span>
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
}
