/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResearchReport = /* GraphQL */ `
  mutation CreateResearchReport(
    $input: CreateResearchReportInput!
    $condition: ModelresearchReportConditionInput
  ) {
    createResearchReport(input: $input, condition: $condition) {
      id
      name
      key
      createdAt
      updatedAt
    }
  }
`;
export const updateResearchReport = /* GraphQL */ `
  mutation UpdateResearchReport(
    $input: UpdateResearchReportInput!
    $condition: ModelresearchReportConditionInput
  ) {
    updateResearchReport(input: $input, condition: $condition) {
      id
      name
      key
      createdAt
      updatedAt
    }
  }
`;
export const deleteResearchReport = /* GraphQL */ `
  mutation DeleteResearchReport(
    $input: DeleteResearchReportInput!
    $condition: ModelresearchReportConditionInput
  ) {
    deleteResearchReport(input: $input, condition: $condition) {
      id
      name
      key
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      segment_competitor
      segment_comapany
      url
      disabled
      users
      metadata
      invitedMembers
      acceptedInvites
      trial_end
      createdAt
      updatedAt
      industry
      company_type
      company_segment
      employee_size
      job_role
      category
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        Targets
        bulletPoints
        packageID
        stripePriceID
        Pricing
        listed
        order
        allowedDashboards
        createdAt
        updatedAt
      }
      website_score {
        items {
          id
          accountID
          overallscore
          page_design_id
          pricing_page_content_id
          ctas_and_support_id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      segment_competitor
      segment_comapany
      url
      disabled
      users
      metadata
      invitedMembers
      acceptedInvites
      trial_end
      createdAt
      updatedAt
      industry
      company_type
      company_segment
      employee_size
      job_role
      category
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        Targets
        bulletPoints
        packageID
        stripePriceID
        Pricing
        listed
        order
        allowedDashboards
        createdAt
        updatedAt
      }
      website_score {
        items {
          id
          accountID
          overallscore
          page_design_id
          pricing_page_content_id
          ctas_and_support_id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      segment_competitor
      segment_comapany
      url
      disabled
      users
      metadata
      invitedMembers
      acceptedInvites
      trial_end
      createdAt
      updatedAt
      industry
      company_type
      company_segment
      employee_size
      job_role
      category
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        Targets
        bulletPoints
        packageID
        stripePriceID
        Pricing
        listed
        order
        allowedDashboards
        createdAt
        updatedAt
      }
      website_score {
        items {
          id
          accountID
          overallscore
          page_design_id
          pricing_page_content_id
          ctas_and_support_id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAccountRequest = /* GraphQL */ `
  mutation CreateAccountRequest(
    $input: CreateAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    createAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountRequest = /* GraphQL */ `
  mutation UpdateAccountRequest(
    $input: UpdateAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    updateAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountRequest = /* GraphQL */ `
  mutation DeleteAccountRequest(
    $input: DeleteAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    deleteAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      Targets
      bulletPoints
      packageID
      stripePriceID
      Pricing
      listed
      order
      allowedDashboards
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      Targets
      bulletPoints
      packageID
      stripePriceID
      Pricing
      listed
      order
      allowedDashboards
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      Targets
      bulletPoints
      packageID
      stripePriceID
      Pricing
      listed
      order
      allowedDashboards
      createdAt
      updatedAt
    }
  }
`;
export const createDashboardItems = /* GraphQL */ `
  mutation CreateDashboardItems(
    $input: CreateDashboardItemsInput!
    $condition: ModelDashboardItemsConditionInput
  ) {
    createDashboardItems(input: $input, condition: $condition) {
      id
      description
      website_grader_description
      grade_chart
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardItems = /* GraphQL */ `
  mutation UpdateDashboardItems(
    $input: UpdateDashboardItemsInput!
    $condition: ModelDashboardItemsConditionInput
  ) {
    updateDashboardItems(input: $input, condition: $condition) {
      id
      description
      website_grader_description
      grade_chart
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardItems = /* GraphQL */ `
  mutation DeleteDashboardItems(
    $input: DeleteDashboardItemsInput!
    $condition: ModelDashboardItemsConditionInput
  ) {
    deleteDashboardItems(input: $input, condition: $condition) {
      id
      description
      website_grader_description
      grade_chart
      createdAt
      updatedAt
    }
  }
`;
export const createDashboardData = /* GraphQL */ `
  mutation CreateDashboardData(
    $input: CreateDashboardDataInput!
    $condition: ModeldashboardDataConditionInput
  ) {
    createDashboardData(input: $input, condition: $condition) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardData = /* GraphQL */ `
  mutation UpdateDashboardData(
    $input: UpdateDashboardDataInput!
    $condition: ModeldashboardDataConditionInput
  ) {
    updateDashboardData(input: $input, condition: $condition) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardData = /* GraphQL */ `
  mutation DeleteDashboardData(
    $input: DeleteDashboardDataInput!
    $condition: ModeldashboardDataConditionInput
  ) {
    deleteDashboardData(input: $input, condition: $condition) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const createWebsiteScore = /* GraphQL */ `
  mutation CreateWebsiteScore(
    $input: CreateWebsiteScoreInput!
    $condition: ModelWebsiteScoreConditionInput
  ) {
    createWebsiteScore(input: $input, condition: $condition) {
      id
      accountID
      overallscore
      page_design_id
      pricing_page_content_id
      ctas_and_support_id
      note
      createdAt
      updatedAt
      page_design {
        id
        score
        design_simplicity
        plan_description
        clear_benefit
        clear_problem
        social_proof
        recommended_plan_highlighted
        at_glance_plan_comparision
        pricing_listed_one_place
        note
        createdAt
        updatedAt
      }
      pricing_page_content {
        id
        score
        published_pricing
        clear_value_metric
        clear_usage_metric
        clear_buyer_personas
        clear_feature_summary
        detailed_features_table
        monthly_or_annual_price
        volume_pricing_menu
        localized_pricing
        note
        createdAt
        updatedAt
      }
      ctas_and_support {
        id
        score
        clear_cta_buttons
        clear_free_trial_cta
        clear_free_plan_cta
        optional_contact_sales_cta
        pricing_specific_faq
        integrated_chat
        note
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateWebsiteScore = /* GraphQL */ `
  mutation UpdateWebsiteScore(
    $input: UpdateWebsiteScoreInput!
    $condition: ModelWebsiteScoreConditionInput
  ) {
    updateWebsiteScore(input: $input, condition: $condition) {
      id
      accountID
      overallscore
      page_design_id
      pricing_page_content_id
      ctas_and_support_id
      note
      createdAt
      updatedAt
      page_design {
        id
        score
        design_simplicity
        plan_description
        clear_benefit
        clear_problem
        social_proof
        recommended_plan_highlighted
        at_glance_plan_comparision
        pricing_listed_one_place
        note
        createdAt
        updatedAt
      }
      pricing_page_content {
        id
        score
        published_pricing
        clear_value_metric
        clear_usage_metric
        clear_buyer_personas
        clear_feature_summary
        detailed_features_table
        monthly_or_annual_price
        volume_pricing_menu
        localized_pricing
        note
        createdAt
        updatedAt
      }
      ctas_and_support {
        id
        score
        clear_cta_buttons
        clear_free_trial_cta
        clear_free_plan_cta
        optional_contact_sales_cta
        pricing_specific_faq
        integrated_chat
        note
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteWebsiteScore = /* GraphQL */ `
  mutation DeleteWebsiteScore(
    $input: DeleteWebsiteScoreInput!
    $condition: ModelWebsiteScoreConditionInput
  ) {
    deleteWebsiteScore(input: $input, condition: $condition) {
      id
      accountID
      overallscore
      page_design_id
      pricing_page_content_id
      ctas_and_support_id
      note
      createdAt
      updatedAt
      page_design {
        id
        score
        design_simplicity
        plan_description
        clear_benefit
        clear_problem
        social_proof
        recommended_plan_highlighted
        at_glance_plan_comparision
        pricing_listed_one_place
        note
        createdAt
        updatedAt
      }
      pricing_page_content {
        id
        score
        published_pricing
        clear_value_metric
        clear_usage_metric
        clear_buyer_personas
        clear_feature_summary
        detailed_features_table
        monthly_or_annual_price
        volume_pricing_menu
        localized_pricing
        note
        createdAt
        updatedAt
      }
      ctas_and_support {
        id
        score
        clear_cta_buttons
        clear_free_trial_cta
        clear_free_plan_cta
        optional_contact_sales_cta
        pricing_specific_faq
        integrated_chat
        note
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPageDesign = /* GraphQL */ `
  mutation CreatePageDesign(
    $input: CreatePageDesignInput!
    $condition: ModelPageDesignConditionInput
  ) {
    createPageDesign(input: $input, condition: $condition) {
      id
      score
      design_simplicity
      plan_description
      clear_benefit
      clear_problem
      social_proof
      recommended_plan_highlighted
      at_glance_plan_comparision
      pricing_listed_one_place
      note
      createdAt
      updatedAt
    }
  }
`;
export const updatePageDesign = /* GraphQL */ `
  mutation UpdatePageDesign(
    $input: UpdatePageDesignInput!
    $condition: ModelPageDesignConditionInput
  ) {
    updatePageDesign(input: $input, condition: $condition) {
      id
      score
      design_simplicity
      plan_description
      clear_benefit
      clear_problem
      social_proof
      recommended_plan_highlighted
      at_glance_plan_comparision
      pricing_listed_one_place
      note
      createdAt
      updatedAt
    }
  }
`;
export const deletePageDesign = /* GraphQL */ `
  mutation DeletePageDesign(
    $input: DeletePageDesignInput!
    $condition: ModelPageDesignConditionInput
  ) {
    deletePageDesign(input: $input, condition: $condition) {
      id
      score
      design_simplicity
      plan_description
      clear_benefit
      clear_problem
      social_proof
      recommended_plan_highlighted
      at_glance_plan_comparision
      pricing_listed_one_place
      note
      createdAt
      updatedAt
    }
  }
`;
export const createPricingPageContent = /* GraphQL */ `
  mutation CreatePricingPageContent(
    $input: CreatePricingPageContentInput!
    $condition: ModelPricingPageContentConditionInput
  ) {
    createPricingPageContent(input: $input, condition: $condition) {
      id
      score
      published_pricing
      clear_value_metric
      clear_usage_metric
      clear_buyer_personas
      clear_feature_summary
      detailed_features_table
      monthly_or_annual_price
      volume_pricing_menu
      localized_pricing
      note
      createdAt
      updatedAt
    }
  }
`;
export const updatePricingPageContent = /* GraphQL */ `
  mutation UpdatePricingPageContent(
    $input: UpdatePricingPageContentInput!
    $condition: ModelPricingPageContentConditionInput
  ) {
    updatePricingPageContent(input: $input, condition: $condition) {
      id
      score
      published_pricing
      clear_value_metric
      clear_usage_metric
      clear_buyer_personas
      clear_feature_summary
      detailed_features_table
      monthly_or_annual_price
      volume_pricing_menu
      localized_pricing
      note
      createdAt
      updatedAt
    }
  }
`;
export const deletePricingPageContent = /* GraphQL */ `
  mutation DeletePricingPageContent(
    $input: DeletePricingPageContentInput!
    $condition: ModelPricingPageContentConditionInput
  ) {
    deletePricingPageContent(input: $input, condition: $condition) {
      id
      score
      published_pricing
      clear_value_metric
      clear_usage_metric
      clear_buyer_personas
      clear_feature_summary
      detailed_features_table
      monthly_or_annual_price
      volume_pricing_menu
      localized_pricing
      note
      createdAt
      updatedAt
    }
  }
`;
export const createCTAsAndSupport = /* GraphQL */ `
  mutation CreateCTAsAndSupport(
    $input: CreateCTAsAndSupportInput!
    $condition: ModelCTAsAndSupportConditionInput
  ) {
    createCTAsAndSupport(input: $input, condition: $condition) {
      id
      score
      clear_cta_buttons
      clear_free_trial_cta
      clear_free_plan_cta
      optional_contact_sales_cta
      pricing_specific_faq
      integrated_chat
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateCTAsAndSupport = /* GraphQL */ `
  mutation UpdateCTAsAndSupport(
    $input: UpdateCTAsAndSupportInput!
    $condition: ModelCTAsAndSupportConditionInput
  ) {
    updateCTAsAndSupport(input: $input, condition: $condition) {
      id
      score
      clear_cta_buttons
      clear_free_trial_cta
      clear_free_plan_cta
      optional_contact_sales_cta
      pricing_specific_faq
      integrated_chat
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteCTAsAndSupport = /* GraphQL */ `
  mutation DeleteCTAsAndSupport(
    $input: DeleteCTAsAndSupportInput!
    $condition: ModelCTAsAndSupportConditionInput
  ) {
    deleteCTAsAndSupport(input: $input, condition: $condition) {
      id
      score
      clear_cta_buttons
      clear_free_trial_cta
      clear_free_plan_cta
      optional_contact_sales_cta
      pricing_specific_faq
      integrated_chat
      note
      createdAt
      updatedAt
    }
  }
`;
export const createGraphDefinition = /* GraphQL */ `
  mutation CreateGraphDefinition(
    $input: CreateGraphDefinitionInput!
    $condition: ModelGraphDefinitionConditionInput
  ) {
    createGraphDefinition(input: $input, condition: $condition) {
      id
      graph_type
      graphql_metric
      graphql_description
      analytics_type
      createdAt
      updatedAt
    }
  }
`;
export const updateGraphDefinition = /* GraphQL */ `
  mutation UpdateGraphDefinition(
    $input: UpdateGraphDefinitionInput!
    $condition: ModelGraphDefinitionConditionInput
  ) {
    updateGraphDefinition(input: $input, condition: $condition) {
      id
      graph_type
      graphql_metric
      graphql_description
      analytics_type
      createdAt
      updatedAt
    }
  }
`;
export const deleteGraphDefinition = /* GraphQL */ `
  mutation DeleteGraphDefinition(
    $input: DeleteGraphDefinitionInput!
    $condition: ModelGraphDefinitionConditionInput
  ) {
    deleteGraphDefinition(input: $input, condition: $condition) {
      id
      graph_type
      graphql_metric
      graphql_description
      analytics_type
      createdAt
      updatedAt
    }
  }
`;
