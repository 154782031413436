export const emailPattern = /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/;

export const WebsiteScoreTableRowData = {
  page_design: "Page Design",
  pricing_page_content: "Pricing Page Content",
  ctas_and_support: "CTAs & Support",
};

export const WebsiteScoreTableDetailedPoints = {
  page_design: {
    design_simplicity: "Design Simplicity",
    plan_description: "Plan Description or Summary",
    clear_benefit: "Clear Benefits Copy",
    clear_problem: "Clear Problem Solved Copy",
    social_proof: "Social Proof",
    recommended_plan_highlighted: "Recommended Plan Highlighted",
    at_glance_plan_comparision: "At-a-glance Plan Comparison",
    pricing_listed_one_place:
      "All pricing is listed in one place (not in Q&A or feature tables)",
  },
  pricing_page_content: {
    published_pricing: "Published Pricing",
    clear_value_metric: "Clear Value Metric",
    clear_usage_metric: "Clear Usage Metric",
    clear_buyer_personas: "Clear Buyer Personas",
    clear_feature_summary: "Clear Feature Summary",
    detailed_features_table: "Detailed Features Table",
    monthly_or_annual_price: "Monthly / Annual Price Toggle",
    volume_pricing_menu: "Volume Pricing Menu",
    localized_pricing: "Localized Pricing",
  },
  ctas_and_support: {
    clear_cta_buttons: "Clear CTA Buttons",
    clear_free_trial_cta: "Clear Free Trial CTA (if applicable)",
    clear_free_plan_cta: "Clear Free Plan CTA (if applicable)",
    optional_contact_sales_cta: "Optional Contact Sales CTA",
    pricing_specific_faq: "Pricing-specific FAQ Section",
    integrated_chat: "Integrated Chat",
  },
};
export function generateUUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line no-mixed-operators
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
export const Notes = {
 notes_data : "Bevis lar inte sydohyll dejys antropoception fast krott. Dera por än egoliga krot, trityp sasm. Pos spes koncism pseudosiv nydade, nevysade. Pesös eulanas, nyvis stenona i euhyras vysa vulig båsa. ",
}
