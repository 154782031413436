/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  Loading3QuartersOutlined,
  MoreOutlined,
  DeleteFilled,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import GradeIcon from "../../assets/Grade.svg";
import sdk from "../../sdk/Accounts";
import {
  createUpdateCTAsAndSupportAPI,
  createUpdatePageDesignAPI,
  createUpdatePricingPageContentAPI,
  createUpdateWebsiteGrade,
  deleteWebsiteScore,
} from "../../store/action/orphanAction";
import { isFloat } from "../../utility/CommonFunctions";
import {
  generateUUID,
  WebsiteScoreTableDetailedPoints,
  WebsiteScoreTableRowData,
} from "../../utility/constants";
import { Line } from "@ant-design/plots";
import { API, graphqlOperation } from "aws-amplify";
import { updateDashboardItems } from "../../graphql/mutations";
import { listDashboardItemss } from "../../graphql/queries";

function WebsiteGrader({ history }) {
  //declarations
  const { selectedCompanyName, groups: account } = useSelector(
    (state) => state.loginReducer
  );
  const dispatch = useDispatch();
  const websitegrader = useRef(null);
  const gradechart = useRef(null);
  const [AccountDetails, setAccountDetails] = useState({});
  const [OverAllscore, setOverAllscore] = useState(0);
  const [website_score, setwebsite_score] = useState({});
  const [tableData, settableData] = useState([]);
  const [SaveLoader, setSaveLoader] = useState(false);
  const isAdminScopping = selectedCompanyName?.length > 0;
  const [AllWebsiteScores, setAllWebsiteScores] = useState([]);
  const [selectedWebsiteScore, setselectedWebsiteScore] = useState("");
  const [website_grader_description, setDescription] = useState();
  const [grade_chart, setGradeChart] = useState();
  const [iconShow, setIconShow] = useState(false);
  const [chartIcon, setCharrtIcon] = useState(false);

  const text = <span>Please edit here</span>;

  const { groups } = useSelector((state) => state.loginReducer);
  const columns = [
    {
      title: <span className="table-cell">Category</span>,
      dataIndex: "category",
      key: "category",
      width: 200,
      render: (item) => (
        <span style={{ fontSize: 16 }}>
          <strong style={{ fontWeight: 600, fontFamily: "Matter-bold" }}>
            {item}
          </strong>
        </span>
      ),
    },
    {
      title: <span className="table-cell">Criteria</span>,
      width: 450,
      dataIndex: "criteria",
      key: "criteria",
      render: (item) => (
        <ul style={{ paddingLeft: 0 }}>
          {item.map((i, key) => {
            return (
              <li key={key} style={{ listStyle: "none", fontSize: 16 }}>
                {i}
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      title: <span className="table-cell">Grade</span>,
      dataIndex: "grade",
      key: "grade",
      width: 100,

      render: (item, record) => (
        <ul style={{ paddingLeft: 0, textAlign: "center" }}>
          {item.map((i, key) => {
            return (
              <li key={key} style={{ listStyle: "none", fontSize: 17 }}>
                {isAdminScopping ? (
                  <>
                    <CheckCircleOutlined
                      style={{
                        color: "green",
                        background: i ? "#b3dab3" : "transparent",
                        padding: 4,
                        borderRadius: 3,
                        cursor: LatestIsNotSelcetedSelected
                          ? "not-allowed"
                          : "pointer",
                      }}
                      onClick={() =>
                        !LatestIsNotSelcetedSelected &&
                        HandleChangeGrade(record, true, key)
                      }
                    />
                    <CloseCircleOutlined
                      style={{
                        color: "red",
                        background: i === false ? "#f8c9c9" : "transparent",
                        padding: 4,
                        borderRadius: 3,
                        cursor: LatestIsNotSelcetedSelected
                          ? "not-allowed"
                          : "pointer",
                      }}
                      onClick={() =>
                        !LatestIsNotSelcetedSelected &&
                        HandleChangeGrade(record, false, key)
                      }
                    />
                  </>
                ) : (
                  <>
                    {i ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    )}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      title: (
        <span className="table-cell" style={{ textAlign: "center" }}>
          Category Score
        </span>
      ),
      dataIndex: "total_category_score",
      key: "total_category_score",
      width: 100,
      render: (item, record) => (
        <h2
          style={{
            color: "#416B88",
            fontSize: "bold",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          <InputNumber
            value={item}
            disabled={
              !selectedCompanyName?.length || LatestIsNotSelcetedSelected
            }
            onChange={(value) => handleChangeTotalCategoryScore(record, value)}
            style={{
              width: isFloat(item) ? 55 : item < 10 ? 28 : 35,
              textAlign: "center",
            }}
            min={0}
            max={10}
            className={
              !selectedCompanyName?.length || LatestIsNotSelcetedSelected
                ? "inputNumberSmall-input"
                : "inputNumberSmall"
            }
            onBlur={(e) => saveTotalCategoryScore(record, e?.target?.value)}
          />
        </h2>
      ),
    },
    {
      title: <span className="table-cell">Analyst Commentary</span>,
      dataIndex: "notes",
      key: "notes",
      render: (item, record) => (
        <span style={{ fontSize: 16 }}>
          <TextArea
            disabled={!selectedCompanyName?.length}
            value={item}
            style={{ border: "none", fontSize: 15 }}
            onChange={(value) => handleChangeNote(record, value)}
            autoSize
            onBlur={(e) => saveNote(record, e?.target?.value)}
          />
        </span>
      ),
    },
  ];

  const handleOverAllScoreChange = (e) => {
    setOverAllscore(e === null ? 0 : e);
  };

  // change account as per scope
  useEffect(() => {
    GetSetAccountDetails(account[0]);
  }, [selectedCompanyName]);

  // change website_score on AccountDetails change
  useEffect(() => {
    setwebsite_score(AccountDetails?.website_score?.items?.[0]);
    setselectedWebsiteScore(AccountDetails?.website_score?.items?.[0]?.id);
  }, [AccountDetails]);

  useEffect(() => {
    if (website_score !== undefined) {
      let data = Object.keys(WebsiteScoreTableRowData)?.map((key, i) => {
        let dataObj = {};
        dataObj["category"] = WebsiteScoreTableRowData[key];
        dataObj["key"] = dataObj["category"];
        dataObj["criteria"] = Object.keys(
          WebsiteScoreTableDetailedPoints[key]
        ).map((item) => WebsiteScoreTableDetailedPoints[key][item]);
        dataObj["grade"] = Object.keys(
          WebsiteScoreTableDetailedPoints[key]
        ).map((item, index) => false);
        dataObj["total_category_score"] = 0;
        dataObj["notes"] = website_score?.[key]?.["note"];
        dataObj["dbKey"] = key;
        return dataObj;
      });
      if (website_score) {
        data?.forEach((item) => {
          if (website_score?.[item.dbKey]) {
            Object.keys(WebsiteScoreTableDetailedPoints[item.dbKey]).forEach(
              (key) => {
                let value = website_score?.[item.dbKey]?.[key];
                let i = item.criteria.indexOf(
                  WebsiteScoreTableDetailedPoints[item.dbKey][key]
                );
                if (i !== -1) {
                  item.grade[i] = value;
                }
              }
            );
            item.total_category_score = website_score?.[item.dbKey]?.score
              ? website_score?.[item.dbKey]?.score
              : 0;
          }
        });

        setOverAllscore(website_score?.overallscore);
        // setwebsiteGraderNote(website_score?.note)
      }
      settableData(data);
      setSaveLoader(false);
    }
  }, [website_score]);

  // setting overall score on edit
  const saveOverAllWebScore = (e) => {
    setSaveLoader(true);
    let inputData = {
      overallscore: OverAllscore,
      accountID: AccountDetails?.id,
    };
    if (website_score) {
      inputData.id = website_score?.id;
    }
    HandleAddEditWebScore(inputData);
  };

  let actions = {
    page_design: createUpdatePageDesignAPI,
    pricing_page_content: createUpdatePricingPageContentAPI,
    ctas_and_support: createUpdateCTAsAndSupportAPI,
  };

  const [uuid1, setuuid1] = useState();
  const [uuid2, setuuid2] = useState();

  // Grade Change in DB
  const HandleChangeGrade = (record, value, key) => {
    record.grade[key] = value;
    let dataToEdit = _.cloneDeep(tableData);
    dataToEdit?.forEach((item) => {
      if (item?.["category"] === record["category"]) {
        item.grade = record.grade;
      }
    });
    settableData(dataToEdit);
    setuuid1(generateUUID());
  };

  const saveData = () => {
    if (uuid1 === uuid2) {
      setSaveLoader(true);
      let inputData = tableData.map((item) => {
        let dataObj = {
          actionType: item?.dbKey,
          actualInputData: {},
        };
        if (website_score?.[item?.dbKey]) {
          dataObj.actualInputData.id = website_score?.[`${item?.dbKey}_id`];
        }
        Object.keys(WebsiteScoreTableDetailedPoints[item?.dbKey]).forEach(
          (value) => {
            let i = item?.["criteria"].indexOf(
              WebsiteScoreTableDetailedPoints[item?.dbKey][value]
            );
            dataObj["actualInputData"][value] = item?.["grade"]?.[i];
          }
        );
        dataObj.actualInputData.score = item?.total_category_score;
        return dataObj;
      });
      Promise.all(
        inputData?.map((item) => {
          let actionToBeCalled = actions[item?.actionType];
          return dispatch(actionToBeCalled(item?.actualInputData));
        })
      ).then((res) => {
        let InputDataForWebScore = {
          id: website_score?.id,
          accountID: AccountDetails?.id,
        };
        res.forEach((item, index) => {
          InputDataForWebScore[`${inputData[index].actionType}_id`] = item.id;
        });
        HandleAddEditWebScore(InputDataForWebScore);
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setuuid2(uuid1);
    }, 2000);
  }, [uuid1]);

  useEffect(() => {
    if (uuid2) saveData();
  }, [uuid2]);

  //Note Change
  const handleChangeNote = (record, value) => {
    record.notes = value?.target.value;
    let dataToEdit = _.cloneDeep(tableData);
    dataToEdit.forEach((item) => {
      if (item?.dbKey === record?.dbKey) {
        item = record;
      }
    });
    settableData(dataToEdit);
  };
  // Only Local Change
  const handleChangeTotalCategoryScore = (record, value) => {
    if (value === null) value = 0;
    record.total_category_score = value;
    let dataToEdit = _.cloneDeep(tableData);
    dataToEdit.forEach((item) => {
      if (item?.dbKey === record?.dbKey) {
        item = record;
      }
    });
    settableData(dataToEdit);
  };

  // SAVE TOTAL CATEGORY SCORE
  const saveTotalCategoryScore = (record, value) => {
    setSaveLoader(true);
    let inputData = {
      id: website_score?.[`${record?.dbKey}_id`],
      score: record?.total_category_score,
    };
    let actionToBeCalled = actions[record?.dbKey];
    dispatch(actionToBeCalled(inputData))
      .then((res) => {
        message.success("Total category score saved");
        if (inputData?.id) {
          GetSetAccountDetails(AccountDetails?.id);
        }
      })
      .catch((err) => {
        setSaveLoader(false);
      });
  };

  //SAVE NOTE CHANGE
  const saveNote = (record, value) => {
    let inputData = {
      id: website_score?.[`${record?.dbKey}_id`],
      note: record?.notes,
    };
    let actionToBeCalled = actions[record?.dbKey];
    dispatch(actionToBeCalled(inputData))
      .then((res) => {
        message.success("Note updated");
        if (inputData?.id) {
          GetSetAccountDetails(AccountDetails?.id);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  // Add or edit webScore
  const HandleAddEditWebScore = (inputData) => {
    setSaveLoader(true);
    dispatch(createUpdateWebsiteGrade(inputData))
      .then((res) => {
        message.success("Website Score Updated");
        GetSetAccountDetails(account[0]);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
        setSaveLoader(false);
      });
  };

  const GetSetAccountDetails = (account) => {
    sdk
      .fetchAccount(account)
      .then((res) => {
        setAccountDetails(res);
        const data = res?.website_score?.items?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setAllWebsiteScores(data);
      })
      .catch((err) => {
        console.log(err);
        setSaveLoader(false);
      });
  };
  // Website score History

  const LatestIsNotSelcetedSelected =
    AllWebsiteScores?.[0]?.id !== selectedWebsiteScore;

  const changeSelectedWebsiteScore = (id) => {
    let data = AllWebsiteScores?.find((item) => item.id === id);
    setwebsite_score(data);
    setselectedWebsiteScore(id);
  };

  const createNewWebsiteGrader = () => {
    let inputData = {
      accountID: AccountDetails?.id,
    };
    HandleAddEditWebScore(inputData);
  };

  // Delete Website Score

  const [deleteWebscoreModal, setdeleteWebscoreModal] = useState(false);

  const HandleDeleteWebsiteGrade = async () => {
    setSaveLoader(true);
    await dispatch(deleteWebsiteScore(website_score))
      .then((res) => {
        GetSetAccountDetails(AccountDetails?.id);
        setdeleteWebscoreModal(false);
        setSaveLoader(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setSaveLoader(false);
      });
  };

  // Duplicate WebsiteGrade

  const handleDuplicateWebsiteGrade = async () => {
    setSaveLoader(true);
    let input_page_design = {};
    let input_ctas_and_support = {};
    let input_pricing_page_content = {};
    if (website_score?.page_design_id) {
      input_page_design = website_score?.page_design;
      delete input_page_design?.id;
      delete input_page_design?.createdAt;
      delete input_page_design?.updatedAt;
    }
    if (website_score?.ctas_and_support_id) {
      input_ctas_and_support = website_score?.ctas_and_support;
      delete input_ctas_and_support?.id;
      delete input_ctas_and_support?.createdAt;
      delete input_ctas_and_support?.updatedAt;
    }
    if (website_score?.pricing_page_content_id) {
      input_pricing_page_content = website_score?.pricing_page_content;
      delete input_pricing_page_content?.id;
      delete input_pricing_page_content?.createdAt;
      delete input_pricing_page_content?.updatedAt;
    }
    let input_website_score = {
      accountID: AccountDetails?.id,
    };

    if (website_score?.page_design_id)
      await dispatch(createUpdatePageDesignAPI(input_page_design))
        .then((res) => {
          input_website_score.page_design_id = res?.id;
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });

    if (website_score?.ctas_and_support_id)
      await dispatch(createUpdateCTAsAndSupportAPI(input_ctas_and_support))
        .then((res) => {
          input_website_score.ctas_and_support_id = res?.id;
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });

    if (website_score?.pricing_page_content_id)
      await dispatch(
        createUpdatePricingPageContentAPI(input_pricing_page_content)
      )
        .then((res) => {
          input_website_score.pricing_page_content_id = res?.id;
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    input_website_score.overallscore = website_score?.overallscore;
    input_website_score.note = website_score?.note;

    dispatch(createUpdateWebsiteGrade(input_website_score))
      .then((res) => {
        GetSetAccountDetails(AccountDetails?.id);
        message.success("Website Score Copied successfully.");
        setSaveLoader(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong.");
        setSaveLoader(false);
      });
  };

  // Website grader Note
  //need in future
  // const [websiteGraderNote, setwebsiteGraderNote] = useState("")

  // const WebsiteScoreNoteChange = (value) => {
  //     setwebsiteGraderNote(value)
  //     let inputData = {
  //         id : website_score?.id,
  //         accountID:AccountDetails?.id,
  //         note : value
  //     }
  //     if(selectedCompanyName?.length)
  //     HandleAddEditWebScore(inputData)
  // }

  // Grader Chart

  const [chartData, setchartData] = useState([]);

  useEffect(() => {
    let AllData = _.cloneDeep(AllWebsiteScores);
    AllData.reverse();
    let data = AllData?.map((item) => {
      return {
        date: moment(item?.updatedAt).format("DD MMM,yyyy"),
        score: item?.overallscore,
      };
    });
    setchartData(data);
  }, [AllWebsiteScores]);

  const config = {
    data: chartData,
    padding: "auto",
    height: 300,
    xField: "date",
    yField: "score",
    xAxis: {
      tickCount: 5,
    },
    label: {},
    point: {
      shape: "circle",
    },
    interactions: [
      {
        type: "custom-marker-interaction",
      },
    ],
  };

  const handleCreateNewWebsiteGrader = () => {
    if (AllWebsiteScores?.[0]?.updatedAt) {
      if (
        moment(AllWebsiteScores?.[0]?.updatedAt).format("DDMMMyyyy") ===
        moment().format("DDMMMyyyy")
      ) {
        sethasDuplicateEntryforTheDay(true);
      } else {
        createNewWebsiteGrader();
      }
    } else {
      createNewWebsiteGrader();
    }
  };

  //handle change function for website grader text
  const handleChange = (e) => {
    setDescription({
      ...website_grader_description,
      website_grader_description: e.target.value,
    });
    setIconShow(true);
  };

  const handleChangeChart = (e) => {
    setGradeChart({
      ...grade_chart,
      grade_chart: e.target.value,
    });
    setCharrtIcon(true);
  };

  //for update website grader description
  const updateDashboard = async (id) => {
    await API.graphql(
      graphqlOperation(updateDashboardItems, {
        input: {
          id: website_grader_description?.id,
          website_grader_description:
            website_grader_description?.website_grader_description,
        },
      })
    )
      .then((res) => {
        message.success("Pricing Page Grader Report Item updated successfully");
        setIconShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for update website grader chart description
  const updateDashboardChart = async (id) => {
    await API.graphql(
      graphqlOperation(updateDashboardItems, {
        input: {
          id: grade_chart?.id,
          grade_chart: grade_chart?.grade_chart,
        },
      })
    )
      .then((res) => {
        message.success("Pricing Page Grader Chart Item updated successfully");
        setCharrtIcon(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for focus input on click event
  const handleClick = () => {
    websitegrader.current.focus();
  };

  //for focus input on click event
  const handleClickFocus = () => {
    gradechart.current.focus();
  };

  //for fetching description items
  const getDashboardItems = async () => {
    await API.graphql(graphqlOperation(listDashboardItemss))
      .then((res) => {
        let data = res?.data?.listDashboardItemss?.items?.[0];
        setDescription(data);
        setGradeChart(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getDashboardItems();
  }, []);

  // check duplicate Entry for the day
  const [hasDuplicateEntryforTheDay, sethasDuplicateEntryforTheDay] =
    useState(false);

  return (
    <>
      {AccountDetails?.plan?.name !== "Starter" ? (
        <div data-tour="tour__websitegrader">
          <Typography.Text
            style={{ fontWeight: 700, fontSize: 18, fontFamily: "Matter-bold" }}
          >
            Pricing Strategy Score
          </Typography.Text>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextArea
              value={website_grader_description?.website_grader_description}
              style={{
                border: "none",
                fontSize: 15,
                paddingLeft: 0,
                background: "transparent",
                color: "black",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              onChange={handleChange}
              ref={websitegrader}
              autoSize
              disabled={!groups.includes("admin")}
            />

            {groups.includes("admin") ? (
              iconShow === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={updateDashboard}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip title={text}>
                  <Button
                    onClick={handleClick}
                    style={{ marginLeft: 10, border: "none" }}
                    icon={
                      <EditOutlined
                        style={{ cursor: "pointer", color: "green" }}
                      />
                    }
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
          <div
            onClick={() => window.open(AccountDetails?.url)}
            style={{
              fontSize: 18,
              cursor: "pointer",
              marginBottom: 8,
              width: "fit-content",
            }}
          >
            {AccountDetails?.url !== " " &&
            AccountDetails?.url !== undefined &&
            AccountDetails?.url !== ""
              ? AccountDetails?.url
              : "URL is not provided. Please specify"}
          </div>

          {isAdminScopping || groups?.includes("admin") ? (
            <>
              <Row>
                <Col
                  span={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{
                    background: "#EFF4F9",
                    padding: 8,
                    borderRadius: 7,
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, display: "flex" }}>
                      <img src={GradeIcon} alt="" style={{ height: 60 }} />
                      <div>
                        <h2
                          style={{
                            marginLeft: 12,
                            marginTop: 10,
                            marginBottom: 0,
                            fontFamily: "Matter-bold",
                            fontWeight: 600,
                          }}
                        >
                          <span>Your Pricing Strategy Score is</span>
                        </h2>
                        {AllWebsiteScores?.length > 0 && (
                          <>
                            <span
                              style={{
                                marginLeft: 12,
                                marginTop: 10,
                                fontSize: 16,
                              }}
                            >
                              Last updated on{" "}
                            </span>
                            <Select
                              value={selectedWebsiteScore}
                              onChange={changeSelectedWebsiteScore}
                              className="webGradeHistory"
                            >
                              {AllWebsiteScores?.map((item, index) => {
                                return (
                                  <Select.Option value={item.id} key={index}>
                                    {moment(item?.updatedAt).format(
                                      "DD MMM,yyyy"
                                    )}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </>
                        )}
                      </div>
                      <h2
                        style={{
                          marginTop: 10,
                          color: "#416B88",
                          fontSize: 20,
                          display: "flex",
                          fontWeight: 600,
                          fontFamily: "Matter-bold",
                        }}
                      >
                        <strong>
                          <InputNumber
                            value={OverAllscore ? OverAllscore : 0}
                            disabled={
                              !selectedCompanyName?.length ||
                              LatestIsNotSelcetedSelected
                            }
                            onChange={handleOverAllScoreChange}
                            style={{
                              width: isFloat(OverAllscore)
                                ? 45
                                : OverAllscore < 10
                                ? 15
                                : 25,
                            }}
                            min={0}
                            max={10}
                            className="inputNumberSmall"
                            onBlur={saveOverAllWebScore}
                          />
                          /10
                        </strong>{" "}
                      </h2>
                      <div style={{ marginLeft: 10, fontSize: 18 }}>
                        {SaveLoader && (
                          <Loading3QuartersOutlined
                            spin
                            style={{ marginTop: 15 }}
                          />
                        )}
                      </div>
                    </div>
                    {/*For Future needed */}
                    {/* <div style={{marginLeft:10,marginTop:15, flex:0.9}}>

            <Typography.Title
            editable={{
          onChange: WebsiteScoreNoteChange,
          editing: selectedCompanyName?.length,
          triggerType: !selectedCompanyName?.length ? ['text'] : ['icon']
        }}
        // disabled={!selectedCompanyName?.length}
        level={5}  
        className='EditableText'      
      >
        {websiteGraderNote}
      </Typography.Title>
            </div> */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {selectedCompanyName?.length ? (
                        <Button
                          className="analytics-btn"
                          style={{
                            color: "#416B88",
                            background: "transparent",
                            borderColor: "#416B88",
                            height: "auto",
                            fontWeight: "bold",
                            padding: "4px 20px",
                            fontSize: 18,
                          }}
                          onClick={handleCreateNewWebsiteGrader}
                        >
                          + Create new website score
                        </Button>
                      ) : (
                        <></>
                      )}
                      {selectedCompanyName?.length ? (
                        <Dropdown
                          trigger={["click"]}
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="Duplicate"
                                onClick={handleDuplicateWebsiteGrade}
                              >
                                <strong>Duplicate website score</strong>
                              </Menu.Item>
                              <Menu.Item
                                key="Delete"
                                onClick={() => setdeleteWebscoreModal(true)}
                              >
                                <strong>Delete website score</strong>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <MoreOutlined style={{ fontSize: "25px" }} />
                        </Dropdown>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col
                span={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ background: "#EFF4F9", padding: 8, borderRadius: 7 }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1, display: "flex" }}>
                    <img src={GradeIcon} alt="" style={{ height: 60 }} />
                    <div>
                      <h2
                        style={{
                          marginLeft: 12,
                          marginTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        <strong>
                          Your Website Grader score is not updated yet
                        </strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextArea
                  value={grade_chart?.grade_chart}
                  style={{
                    border: "none",
                    fontSize: 15,
                    paddingLeft: 0,
                    background: "transparent",
                    color: "black",
                    cursor: !groups.includes("admin") ? "default" : "",
                  }}
                  onChange={handleChangeChart}
                  ref={gradechart}
                  autoSize
                  disabled={!groups.includes("admin")}
                />
                {groups.includes("admin") ? (
                  chartIcon === true ? (
                    <Button
                      style={{ marginLeft: 10, border: "none" }}
                      onClick={updateDashboardChart}
                      icon={
                        <CheckOutlined
                          style={{ cursor: "pointer", color: "green" }}
                        />
                      }
                    ></Button>
                  ) : (
                    <Tooltip title={text}>
                      <Button
                        onClick={handleClickFocus}
                        style={{ marginLeft: 10, border: "none" }}
                        icon={
                          <EditOutlined
                            style={{ cursor: "pointer", color: "green" }}
                          />
                        }
                      ></Button>
                    </Tooltip>
                  )
                ) : null}
              </div>
            </Col>
          </Row>
          <div style={{ height: 200, width: "100%", margin: "20px 0px" }}>
            <Line {...config} />
          </div>
          {AccountDetails?.url !== " " &&
            AccountDetails?.url !== undefined &&
            AccountDetails?.url !== "" && (
              <Table
                className="tablecell"
                style={{ paddingTop: 12, borderRadius: 8 }}
                bordered
                size="middle"
                dataSource={tableData}
                columns={columns}
                pagination={{ position: ["none"] }}
              ></Table>
            )}

          {deleteWebscoreModal && (
            <Modal
              className="delete-budget-camp"
              visible={deleteWebscoreModal}
              closable={false}
              centered
              footer={[]}
              style={{ borderRadius: "20px" }}
            >
              <div
                className="delete-modal-body"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <DeleteFilled style={{ fontSize: 50, color: "#416B88" }} />
                <Typography.Text
                  style={{
                    marginTop: "10px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 10,
                  }}
                >
                  Are you sure you want to delete this website score?
                </Typography.Text>
                <div className="custom-modal-footer">
                  <Button
                    type="primary"
                    size="large"
                    onClick={HandleDeleteWebsiteGrade}
                    loading={SaveLoader}
                    className="yes-btn"
                  >
                    Yes
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => setdeleteWebscoreModal(false)}
                    ghost
                    style={{ marginLeft: 10 }}
                    disabled={SaveLoader}
                    className="no-btn"
                  >
                    No
                  </Button>
                </div>
              </div>
            </Modal>
          )}
          <Modal
            className="create-new-modal"
            visible={hasDuplicateEntryforTheDay}
            closable={false}
            centered
            footer={[]}
            style={{ borderRadius: "20px" }}
          >
            <div
              className="delete-modal-body"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography.Title
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  textAlign: "center",
                  paddingBottom: 15,
                }}
              >
                Do you want to add another grade entry for the same day?
              </Typography.Title>
              <div className="custom-modal-footer">
                <Button
                  className="btn-css"
                  size="large"
                  onClick={() => {
                    createNewWebsiteGrader();
                    sethasDuplicateEntryforTheDay(false);
                  }}
                  loading={SaveLoader}
                >
                  Yes
                </Button>
                <Button
                  className="btn-css1"
                  size="large"
                  onClick={() => {
                    sethasDuplicateEntryforTheDay(false);
                  }}
                  ghost
                  style={{ marginLeft: 10 }}
                  disabled={SaveLoader}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={GradeIcon} alt="" style={{ height: 60 }} />
              <div style={{ marginLeft: 12 }}>
                <h2 style={{ marginBottom: 0 }}>
                  <strong>
                    Website Grader score of your Pricing Plan page
                  </strong>
                </h2>
                <h2>
                  Get ideas on Improving your pricing plan page structure for
                  better conversion rate.
                </h2>
              </div>
            </div>
            <Button
              size="large"
              style={{ height: "auto", padding: "7px 25px", fontSize: 16 }}
              onClick={() => history.push("/settings")}
            >
              Upgrade Plan
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default WebsiteGrader;
