import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import CreateAccount from "../pages/user/CreateAccount";
import Login from "../pages/user/Login";
import PublicRoutes from "./PublicRoutes";
import MainLayoutRoute from "./MainLayoutRoute";
import AccountLayoutRoute from "./AccountRoute";
import { createBrowserHistory } from "history";
import ResetPassword from "../pages/user/ResetPassword";
import ConfirmEmail from "../pages/user/CreateAccount/ConfirmEmail";
import ForgotPassword from "../pages/user/ForgotPassword";
import ConfirmPassword from "../pages/user/ForgotPassword/ConfirmPassword";
import Selection from "../pages/user/Accounts/Selection";
import BillingPlan from "../pages/billing/index";
import CompanyDetail from "../pages/user/Accounts/CompanySettings";
import UserTab from "../pages/usertab";
import Admin from "../pages/Admin";
import UserSettings from "../pages/account/UserSettings";
import CreatePassword from "../pages/user/Login/CreatePassword";
import PlanSelected from "../pages/billing/PlanSelected";
import MyDashboard from "../pages/MyDashboard/mydashboard";
import PlanIntroductionModel from "../common/PlanIntroductionModel";
import CompanyDetailFill from "../common/CompanyDetail";
import PackagingModel from "../pages/Analytics/PackagingModel";
import PricingModel from "../pages/Analytics/PricingModel";
import DiscountingModel from "../pages/Analytics/PricingandDiscounting";
import PrivacyPolicy from "../pages/user/CreateAccount/PrivacyPolicy";
import GraphDefinition from "../pages/GraphDescription/GraphDescription";
import Support from "../pages/Support/Support";
import CompanyProfile from "../pages/Companyprofiles/CompanyProfile";
import CompanyPreview from "../pages/Companyprofiles/CompanyPreview";

export const Routes = () => {
  return (
    <BrowserRouter history={createBrowserHistory()}>
      <Switch>
        <PublicRoutes
          exact
          path={"/signin"}
          render={(matchprops) => <Login {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/resetpassword"}
          render={(matchprops) => <ResetPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/signup"}
          render={(matchprops) => <CreateAccount {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/confirm_email?confirmationcode=:code"}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/confirm_email"}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/forgotpassword"}
          render={(matchprops) => <ForgotPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/reset-password"}
          render={(matchprops) => <ConfirmPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/reset-password?confirmationcode=:code+email=:email"}
          render={(matchprops) => <ConfirmPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={"/create-password"}
          render={(matchprops) => <CreatePassword {...matchprops} />}
        />
        <AccountLayoutRoute
          exact
          path={"/setup?requestid=:id"}
          render={(matchprops) => <Selection {...matchprops} />}
        />
        <AccountLayoutRoute
          exact
          path={"/setup"}
          render={(matchprops) => <Selection {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/myprofile"}
          render={(matchprops) => <UserSettings {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/company"}
          render={(matchprops) => <CompanyDetail {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/settings"}
          render={(matchprops) => <BillingPlan {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={"/"}
          render={(matchprops) => <MyDashboard {...matchprops} />}
        />

        <Route path={"/thankyou"} component={PlanSelected} />

        <MainLayoutRoute
          exact
          path={"/users"}
          render={(matchprops) => <UserTab {...matchprops} />}
        />
        <MainLayoutRoute
          path={"/admin"}
          render={(matchprops) => <Admin {...matchprops} />}
        />
        <MainLayoutRoute
          path={"/model"}
          render={(matchprops) => <PlanIntroductionModel {...matchprops} />}
        />
        <MainLayoutRoute
          path="/details"
          render={(matchprops) => <CompanyDetailFill {...matchprops} />}
        />
        <MainLayoutRoute
          path="/analytics/packaging-models"
          render={(matchprops) => <PackagingModel {...matchprops} />}
        />
        <MainLayoutRoute
          path="/analytics/pricing-models"
          render={(matchprops) => <PricingModel {...matchprops} />}
        />
        <MainLayoutRoute
          path="/analytics/pricing-discounting"
          render={(matchprops) => <DiscountingModel {...matchprops} />}
        />
        <MainLayoutRoute
        path="/support"
        render={(matchprops) => <Support {...matchprops} /> }
        />
        <MainLayoutRoute
        path="/graph-definition"
        render={(matchprops) => <GraphDefinition {...matchprops} />}
        />
        <MainLayoutRoute 
        path="/company-preview"
        render={(matchprops) => <CompanyPreview {...matchprops}/>}
        />
         <MainLayoutRoute 
        path="/company-profile/:id"
        render={(matchprops) => <CompanyProfile {...matchprops}/>}
        />
        <Route path="/privacy" component={PrivacyPolicy} />

      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
