import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton } from "antd";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import convert from "xml-js";
import { useSelector } from "react-redux";

function Blogs() {
  const [BlogsData, setBlogsData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const { token } = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (token !== null) {
      setLoader(true);
      API.get("XaaSPricingREST", "/blogs")
        .then((data) => {
          let BlogData = JSON.parse(convert.xml2json(data));
          let blogs = [];
          BlogData?.elements
            ?.find((item) => item?.type === "element")
            ?.elements?.[0]?.elements?.filter((item) => item?.name === "item")
            ?.map((item) => item?.elements)
            ?.forEach((item) => {
              let dataObj = {};
              dataObj.title = item.find(
                (i) => i.name === "title"
              )?.elements?.[0]?.text;
              dataObj.link = item.find(
                (i) => i.name === "link"
              )?.elements?.[0]?.text;
              dataObj.description = item.find(
                (i) => i.name === "description"
              )?.elements?.[0]?.cdata;
              let content = item.find((i) => i.name === "content:encoded")
                ?.elements?.[0]?.cdata;
              // eslint-disable-next-line no-useless-escape
              dataObj.img = String(content)
                .match(/\bhttps?:\/\/\S+/gi)
                .filter(
                  (item) =>
                    item.includes("jpg") ||
                    item.includes("bmp") ||
                    item.includes("gif") ||
                    item.includes("png")
                )
                .find((i) => !String(i).includes(`\\"`));
              blogs.push(dataObj);
            });
          blogs.splice(4);
          setBlogsData(blogs);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(true);
        });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Row>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <h2
          style={{ fontWeight: 600, fontSize: 18, fontFamily: "Matter-bold" }}
        >
          Blogs{" "}
          {Loader && (
            <Loading3QuartersOutlined spin style={{ marginLeft: 5 }} />
          )}
        </h2>
        {Loader && <div className="dashboard-loader">Loading;</div> ? (
          <Row>
            <Col span={24}>
              <Skeleton.Input
                active
                style={{ borderRadius: "7px", height: "35px" }}
              />
            </Col>
          </Row>
        ) : (
          <Button
            className="analytics-btn"
            style={{
              color: "#416B88",
              borderColor: "#416B88",
              fontWeight: "bold",
              fontSize: 16,
              height: "auto",
              padding: "7px 25px",
            }}
            onClick={() =>
              window.open("https://www.xaaspricing.com/category/news")
            }
          >
            Show All
          </Button>
        )}
      </div>
      {Loader && <div className="dashboard-loader">Loading;</div> ? (
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row" span={6}>
            <Skeleton.Button
              style={{ width: "17vw", borderRadius: 7, height: "250px" }}
              active
            />
          </Col>
          <Col className="gutter-row" span={6}>
            <Skeleton.Button
              style={{ width: "17vw", borderRadius: 7, height: "250px" }}
              active
            />
          </Col>
          <Col className="gutter-row" span={6}>
            <Skeleton.Button
              style={{ width: "17vw", borderRadius: 7, height: "250px" }}
              active
            />
          </Col>
          <Col className="gutter-row" span={6}>
            <Skeleton.Button
              style={{ width: "17vw", borderRadius: 7, height: "250px" }}
              active
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={[20, 20]} className="blogs">
          {BlogsData?.map((item) => (
            <Col
              span={6}
              className="blog-wrapper"
              onClick={() => window.open(item?.link)}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                alt={item.title}
                src={item.img}
                style={{ height: 190, width: "100%", borderRadius: 7 }}
                className="responsive-width"
              />
              <span
                style={{ marginTop: 10, fontSize: 18 }}
                className="responsive-width title-hover"
              >
                {item.title}
              </span>
              <span
                style={{
                  color: "#416B88",
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: "Matter-bold",
                }}
              >
                Read More
              </span>
            </Col>
          ))}
        </Row>
      )}
    </Row>
  );
}

export default Blogs;
