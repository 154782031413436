import React, { Component } from "react";
import Index from "../Login/background";
import { Row, Alert, Col, Input, Form, Button } from "antd";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import Footer from "../../../common/footer";
import { Auth } from "aws-amplify";
import { emailPattern } from "../../../utility/constants";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      error: "",
      loading: false,
    };
  }

  // Function to handle reset password
  handleResetPassword = (e) => {
    this.setState(
      {
        loading: true,
        error: "",
      },
      async () => {
        try {
          await Auth.forgotPassword(e.email);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.history.push({
                pathname: `/reset-password`,
                state: { userName: e.email },
              });
            }
          );
        } catch (error) {
          console.log("error resetting password:", error);
          this.setState({
            error: error.message,
            loading: false,
          });
        }
      }
    );
  };
  render() {
    const { error, loading } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center">
        <Index />
        <Col span={12} md={12} lg={12} xs={24}>
          <Row
            gutter={24}
            justify="center"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
              width: "100%",
              position: "relative",
              margin: 0,
            }}
          >
            <Col
              span={12}
              md={24}
              lg={12}
              xs={24}
              sm={24}
              style={{ textAlign: "left" }}
            >
              <img
                src={Xaaslogo}
                alt=""
                style={{ display: "flex", margin: "0 auto", height: 100 }}
              />
              {error && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                requiredMark={false}
                layout="vertical"
                name="normal_login"
                className="login-form"
                onFinish={(e) => this.handleResetPassword(e)}
              >
                <Form.Item
                  label={<span style={{ fontSize: 18 }}>Email</span>}
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    id="email"
                    key="email"
                    name="email"
                    disabled={loading}
                    style={{
                      borderRadius: "8px",
                      height: "44px",
                      fontSize: 18,
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={8}>
                      <Button
                        type="link"
                        onClick={() => history.push("/signin")}
                        disabled={loading}
                      >
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            textAlign: "center",
                            color: "#416B88",
                          }}
                        >
                          Login
                        </span>
                      </Button>
                    </Col>
                    <Col span={10}>
                      <Button
                        size="large"
                        htmlType="submit"
                        block
                        tabIndex={5}
                        loading={loading}
                        style={{
                          background: "#416B88",
                          color: "#FFFFFF",
                          borderRadius: "8px",
                          height: "44px",
                          marginBottom: 0,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        <span>Send code</span>
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>
    );
  }
}
export default ForgotPassword;
