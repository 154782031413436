import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Modal,
  Form,
  Space,
  Input,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import JoinAccount from "../../../assets/JoinAccount.svg";
import RequestPending from "./RequestPending";
import sdk from "../../../sdk/Accounts";
import "../user.css";

function RequestAccess({ expand, select, reset, accountid, requestAccess }) {
  const [pending, setPending] = useState(false);

  // Function to handle request
  const handleSubmit = async (e) => {
    let response = await sdk.requestAccess(e.accountid);

    if (response.errors || response.error) {
      message.error(response.errors || response.error);
    } else {
      setPending(true);
      requestAccess();
      message.success("Your request has been submitted");
    }

    reset();
  };

  useEffect(() => {
    if (accountid) {
      if (accountid.length === 36) {
        var patternId = new RegExp(
          "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"
        );
        if (patternId.test(accountid)) {
          handleSubmit({ accountid: accountid });
        }
      } else {
        message.error("Account id is not valid");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountid]);

  return (
    <>
      {pending ? (
        <RequestPending accountid={accountid} />
      ) : (
        <Card style={{ border: "none" }}>
          <Row>
            <Col
              xs={24}
              md={24}
              style={{
                background: "#D9E9F5",
                borderRadius: "20px",
                padding: "33px",
              }}
            >
              <div style={{ width: "100%", margin: "30px 0px" }}>
                <img
                  src={JoinAccount}
                  alt="new account"
                  style={{ width: "100%", maxHeight: "190px" }}
                />
              </div>
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  color: "#303030",
                  fontWeight: 600,
                }}
              >
                Join Existing Account
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  color: "#303030",
                }}
              >
                You can request access to an existing account <br /> if you know
                the account identification number.
              </Typography.Paragraph>
              <Button
                type="primary"
                size="large"
                block
                style={{
                  border: "none",
                  backgroundColor: "#416B88",
                  borderRadius: "7px",
                  fontSize: "18px",
                  color: "#FFFFFF",
                  width: "60%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  fontWeight: "bold",
                }}
                onClick={select}
              >
                Request Access
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      <Modal
        className="new-account-model"
        visible={expand}
        closable={true}
        footer={[]}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              style={{
                fontSize: 20,
                marginTop: 10,
                color: "#ffffff",
                marginLeft: "60px",
                transform: "scale(1.5)",
              }}
              onClick={reset}
            />
          </strong>
        }
      >
        <Row justify="center">
          <Col span={20} lg={20} md={20} sm={24} xs={24}>
            <Typography.Title
              style={{ textAlign: "center", fontSize: 18 }}
              level={4}
            >
              Customer ID From Your Company
            </Typography.Title>
            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="accountid"
                  className="form-input"
                  label={<span style={{ fontSize: 18 }}>Customer ID</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Account ID of your company",
                    },
                    {
                      pattern:
                        /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
                      message: "Please enter valid Account ID",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="accountid"
                    key="accountid"
                    name="accountid"
                    tabIndex={1}
                    style={{ height: "44px", fontSize: 18 }}
                    defaultValue={accountid}
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    className="new-account-btn"
                    tabIndex={5}
                    style={{ fontSize: 18, fontWeight: 600, height: "auto" }}
                  >
                    Get Started
                  </Button>
                </Form.Item>
                <p
                  style={{
                    fontSize: 16,
                    textAlign: "center",
                    color: "#727272",
                    cursor: "pointer",
                    marginBottom: "0",
                  }}
                >
                  Don't know your organization ID number? <br />
                  <span
                    style={{
                      color: "#416B88",
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    Contact XaaS Pricing support
                  </span>
                </p>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default RequestAccess;
