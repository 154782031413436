import React from "react";
import background from "../../../assets/background.svg";
import "../user.css";
import { Col, Typography } from "antd";

function Index() {
  return (
    <Col
      span={12}
      xs={0}
      md={12}
      lg={12}
      style={{
        height: "100vh",
        width: "100%",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="login-bg"
    >
      <Col className="info">
        <div className="info-text">
          <Typography.Title className="login-heading-text">
            Level Up Your XaaS Pricing <br /> Strategy Fast{" "}
          </Typography.Title>
          <Typography.Title
            className="login-heading-text-font"
            style={{ fontWeight: 400, margin: 0, fontSize: 30 }}
          >
            On-demand, Data-driven Pricing Analysis <br /> To Fuel Better,
            Faster Pricing Decisions{" "}
          </Typography.Title>
        </div>
      </Col>
    </Col>
  );
}

export default Index;
