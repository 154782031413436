export const getAccountCustom = /* GraphQL */ `
query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    company
    company_loweredcased
    company_description
    url
    disabled
    users
    metadata
    createdAt
    updatedAt
    industry
    company_type
    company_segment
    segment_competitor
    segment_comapany
    employee_size
    job_role
    category
    accountStatus
    stripeCustomerID
    stripeSubscriptionID
    stripePriceID
    planID
    owners
    editors
    viewers
    requests {
      items {
        id
        account
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
    plan {
      id
      name
      Targets
      bulletPoints
      packageID
      stripePriceID
      Pricing
      listed
      order
      createdAt
      updatedAt
    }
    website_score(sortDirection: DESC) {
      items {
        id
        accountID
        overallscore
        page_design_id
        pricing_page_content_id
        ctas_and_support_id
        note
        createdAt
        updatedAt
        page_design {
          id
          score
          design_simplicity
          plan_description
          clear_benefit
          clear_problem
          social_proof
          note
          recommended_plan_highlighted
          at_glance_plan_comparision
          pricing_listed_one_place
          createdAt
          updatedAt
        }
        pricing_page_content {
          id
          score
          published_pricing
          clear_value_metric
          clear_usage_metric
          clear_buyer_personas
          clear_feature_summary
          detailed_features_table
          monthly_or_annual_price
          volume_pricing_menu
          localized_pricing
          note
          createdAt
          updatedAt
        }
        ctas_and_support {
          id
          score
          clear_cta_buttons
          clear_free_trial_cta
          clear_free_plan_cta
          optional_contact_sales_cta
          pricing_specific_faq
          integrated_chat
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
    invitedMembers
  }
}
`;