import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Modal,
  Form,
  Space,
  Input,
  message,
} from "antd";
import newaccount from "../../../assets/newaccount.svg";
import { CloseOutlined } from "@ant-design/icons";
import "../user.css";
import sdk from "../../../sdk/Accounts";
import { Auth } from "aws-amplify";
import {
  setAccountDetails,
  setLoginData,
  setFirstTimeUser,
} from "../../../store/action/loginAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

function NewAccount({ expand, select, reset, fetchuser }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  //Function to handle submit of create account
  const handleSubmit = async (e) => {
    setLoading(true);
    let result = await sdk.createAccount(e.company, e.url);

    if (result.error) {
      message.error(result.error, 5);
    } else {
      message.success(`Account Created Successfully`, 5);
      history.push("/details");
      dispatch(setFirstTimeUser(true));
      await fetchUser();
    }
    setLoading(false);
    reset();
  };

  // Function to fetch user account
  const fetchUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      async (err, session) => {
        const response = Auth.currentUserInfo();
        let groups = session?.accessToken?.payload?.["cognito:groups"];
        if (groups?.length > 0) {
          let res = await sdk.fetchAccount(groups[0]);
          dispatch(setAccountDetails(res));
        }
        response.then((data) => {
          dispatch(setLoginData(session, data?.attributes));
        });
      }
    );
  };

  return (
    <>
      <Card style={{ border: "none" }}>
        <Row>
          <Col
            xs={24}
            md={24}
            style={{
              background: "#416B88",
              borderRadius: "20px",
              padding: "33px",
            }}
          >
            <div style={{ width: "100%", margin: "30px 0px" }}>
              <img
                src={newaccount}
                alt="new account"
                style={{ width: "100%", maxHeight: "190px" }}
              />
            </div>
            <Typography.Title
              level={4}
              style={{
                textAlign: "center",
                fontSize: "22px",
                color: "#FFFFFF",
                fontWeight: 600,
              }}
            >
              Start New Account
            </Typography.Title>
            <Typography.Paragraph
              style={{
                fontWeight: "normal",
                fontSize: "18px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                color: "#FFFFFF",
              }}
            >
              Start your free trial now, if no one at your <br /> company has
              set up an account with us.
            </Typography.Paragraph>
            <Button
              type="primary"
              size="large"
              block
              onClick={select}
              style={{
                border: "none",
                backgroundColor: "#D9E9F5",
                borderRadius: "7px",
                fontSize: "18px",
                color: "#416B88",
                width: "60%",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
                fontWeight: "bold",
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Card>
      <Modal
        className="new-account-model"
        visible={expand}
        closable={true}
        footer={[]}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              style={{
                fontSize: 20,
                marginTop: 10,
                color: "#ffffff",
                marginLeft: "60px",
                transform: "scale(1.5)",
              }}
              onClick={reset}
            />
          </strong>
        }
      >
        <Row justify="center">
          <Col span={20} lg={20} md={20} sm={24} xs={24}>
            <Typography.Title
              style={{ textAlign: "center", marginBottom: 3, fontSize: 18 }}
            >
              Company Details
            </Typography.Title>
            <Typography.Title
              style={{
                textAlign: "center",
                marginTop: 9,
                marginBottom: 10,
                color: "#727272",
                lineHeight: "20px",
                fontSize: "16px",
              }}
              level={5}
            >
              Add Details Of Your Organization
              <br /> To Create New Account
            </Typography.Title>
            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="company"
                  className="form-input"
                  label={<span style={{ fontSize: 18 }}>Company name</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of your company",
                    },
                    {
                      pattern: /^[ A-Za-z0-9_@./#&+-]*$/,
                      message: "Please enter valid company name",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="company"
                    key="company"
                    name="company"
                    tabIndex={1}
                    style={{ height: "44px", fontSize: "18px" }}
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item
                  name="url"
                  className="form-input"
                  label={<span style={{ fontSize: 18 }}>Company URL</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the URL of your company",
                    },
                    {
                      pattern:
                        /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                      message: "Please enter valid URL",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="url"
                    key="url"
                    name="url"
                    tabIndex={1}
                    disabled={loading}
                    style={{ height: "44px", fontSize: 18 }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    className="new-account-btn"
                    tabIndex={5}
                    loading={loading}
                    style={{ fontSize: 18, fontWeight: 600, height: 44 }}
                  >
                    Get Started
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default NewAccount;
