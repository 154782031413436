import React, { useEffect, useState } from "react";
import Index from "../Login/background";
import { Row, Col, Form, Input, Button, message, Alert } from "antd";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import Footer from "../../../common/footer";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import OtpInput from "react-otp-input";

function ConfirmEmail(props) {
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [email] = useState(props.location.state?.userName);
  const history = useHistory();
  const [form] = Form.useForm();
  const [otp, setOtp] = useState();

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm_email")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        Data.email = window.location.href.split("email=")[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle resend Code
  const handleResendCode = async () => {
    setloading(true);
    seterror("");
    try {
      await Auth.resendSignUp(email);
      message.success("A new confirmation code has been sent.");
      setloading(false);
    } catch (error) {
      console.log("error signing up:", error);
      seterror(error.message);
      setloading(false);
    }
  };

  //Fucntion to handle confirmed sign up
  const handleConfirmSignUp = async (e) => {
    try {
      if (e.email && e.code) {
        setloading(true);
        seterror("");
        await Auth.confirmSignUp(e.email, e.code);
        setloading(false);
        history.push({
          pathname: "/signin",
          state: {
            feedback: "Sign in with your new account",
            userName: e.email,
          },
        });
      } else {
        message.error("something went wrong");
      }
    } catch (error) {
      if (
        error.message.includes(
          "User cannot be confirmed. Current status is CONFIRMED"
        )
      ) {
        history.push("/");
        message.success("Already Verified");
      }
      console.log("error signing up:", error);
      seterror(error.message);
      setloading(false);
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm_sigin")
      ) {
        let Data = {};

        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        Data.email = window.location.href.split("email=")[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
        handleConfirmSignUp(form.getFieldsValue(["email", "code"]));
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row justify="center">
      <Index />
      <Col span={12} md={12} lg={12} xs={24}>
        <Row
          justify="center"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          <Col span={12} style={{ textAlign: "center" }}>
            <img src={Xaaslogo} alt="" style={{ height: 100 }} />
            {error !== "" && (
              <Alert
                style={{ marginBottom: "10px" }}
                type="error"
                showIcon={false}
                message={error}
                banner
                closable
              />
            )}
            <Form
              form={form}
              layout="vertical"
              name="normal_signup"
              className="signup-form"
              requiredMark={false}
              onFinish={(e) => handleConfirmSignUp(e)}
            >
              <h4
                style={{
                  color: "#416B88",
                  textAlign: "center",
                  fontWeight: "600",
                  margin: "10px",
                  fontSize: 18,
                }}
              >
                Confirm Your Email Id <br />{" "}
                <p
                  style={{ color: "#727272", fontWeight: "400", fontSize: 18 }}
                >
                  {" "}
                  Enter code sent in your email{" "}
                </p>
              </h4>
              <Form.Item
                initialValue={email}
                label={<span style={{ fontSize: 18 }}>Email</span>}
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <Input
                  value={email}
                  id="email"
                  key="email"
                  name="email"
                  placeholder="Email"
                  disabled={localStorage.getItem("User")}
                  style={{ color: "gray", fontSize: "18px", height: 44 }}
                />
              </Form.Item>

              <Form.Item
                className="otp"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirmation Code!",
                  },
                ]}
              >
                <OtpInput
                  name="code"
                  shouldAutoFocus
                  className="otp-input"
                  id="code"
                  key="code"
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span style={{ color: "white" }}> ---</span>}
                />
              </Form.Item>

              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Col span={8}>
                    <Button
                      type="link"
                      onClick={handleResendCode}
                      disabled={loading}
                      style={{
                        fontSize: 18,
                        textAlign: "right",
                        cursor: "pointer",
                        paddingBottom: 10,
                        color: "#416B88",
                        fontWeight: "bold",
                        paddingLeft: 0,
                      }}
                    >
                      Resend Code
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      style={{
                        background: "#416B88",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        height: "auto",
                        width: "auto",
                        marginBottom: 0,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                      loading={loading}
                    >
                      <span>Confirm Email</span>
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
}

export default ConfirmEmail;
