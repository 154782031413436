import React, { useEffect, useState } from "react";
import { Select, Button, message, Input, Row, Col } from "antd";
import { API } from "aws-amplify";
import sdk from "../../sdk/Accounts";
import axios from "axios";

const { Option } = Select;
function ManageData() {
  const [data, setData] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uplaodLoader, setUplaodLoader] = useState(false);

  const downloadData = async () => {
    setLoading(true);
    let result = await sdk.downloadData(dropdown);
    window.open(result, "_blank");
    setLoading(false);
  };

  const uploadCsv = async (file) => {
    setUplaodLoader(true);
    let fileName = file?.name;
    await sdk
      .uplaodFile(fileName)
      .then(async (res) => {
        const formData = new FormData();
        formData.append("file", file);
        await axios
          .put(res, file, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
          })
          .then(async (res) => {
            let fileName = file?.name;
            let data = {
              table: dropdown,
              filename: fileName,
            };
            await sdk.uploadToS3(data);
          });
        setUplaodLoader(false);
        message.success("file uploaded successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to fetch table data
  const getTableData = async (data) => {
    let apiURLData = "";
    apiURLData = `/redshiftDataManager/getTables`;
    await API.get("XaaSPricingREST", apiURLData)
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      {uplaodLoader && <div className="loading">Loading;</div>}
      <Row justify="center" className="manageData">
        <Col span={8} lg={8} md={8} sm={24} xs={24}>
          <Select
            style={{ width: 200, fontSize: 16, lineHeight: "44px !important" }}
            className="invitemebers"
            size="large"
            onChange={(e) => setDropdown(e)}
            placeholder="Select a table"
          >
            {data.map((value, index) => {
              return (
                <Option key={index} value={value} style={{ fontSize: 16 }}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={8} lg={8} md={8} sm={24} xs={24}>
          <Button
          className="save-btn"
            style={{
              height: "auto",
              background: "#416B88",
              color: "white",
              width: "auto",
              fontWeight: "bold",
              fontSize: 18,
              padding: "5px 18px",
            }}
            onClick={downloadData}
            disabled={dropdown?.length > 0 ? false : true}
            loading={loading}
          >
            Download
          </Button>
        </Col>
        <Col span={8} lg={8} md={8} sm={24} xs={24}>
          <Input
            type="file"
            id="myFile"
            name="filename"
            style={{ height: "44px", fontSize: 16, paddingTop: 5 }}
            onChange={(event) => {
              uploadCsv(event?.target?.files[0]);
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default ManageData;
