//LoginActions
export const LOGIN = "LOGIN";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const SET_PERMISSION = "SET_PERMISSION";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const LOGOUT = "LOGOUT";
export const SET_ACCOUNT_REQUESTS = "SET_ACCOUNT_REQUESTS";
export const UPDATE_COMPANY_ACCOUNT = "UPDATE_COMPANY_ACCOUNT";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const SET_DROPDOWN = "SET_DROPDOWN";
export const SET_AUTHOR = "SET_AUTHOR";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_SUBSCRIPTION_DETAILS = "SET_SUBSCRIPTION_DETAILS";
export const SET_FIRSTTIMEUSER = "SET_FIRSTTIMEUSER";
export const SET_GROUP_VALUE = "SET_GROUP_VALUE"
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
