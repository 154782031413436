import {
  Tabs,
  Typography,
  Button,
  Drawer,
  Input,
  Form,
  Space,
  Skeleton,
  message,
  Grid,
} from "antd";
import React, { useEffect, useState } from "react";
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";
import sdk from "../../../sdk/Accounts";
import { useSelector } from "react-redux";

function CompanyDetail() {
  const { groups } = useSelector((state) => state.loginReducer);
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(true);
  const [acct, setAcct] = useState(null);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [UpdateCompanyModelOpen, setUpdateCompanyModelOpen] = useState(false);
  const [companyAccountForm] = Form.useForm();

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      setCompanyData(account);
      setLoading(false);
    }
  };

  const setCompanyData = (account) => {
    setAcct(account);
  };

  const updateCompany = (e) => {
    setLoading(true);
    delete e.url;
    let reqData = {
      id: acct?.id,
      company_loweredcased: String(e?.company).toLowerCase(),
      ...e,
    };
    sdk
      .companyUpdate(reqData)
      .then((data) => {
        if (data?.errors) {
          message.error("Something went wrong");
          setLoading(false);
        } else {
          data.url = acct?.url;
          setCompanyData(data);
          setUpdateCompanyModelOpen(false);
          setLoading(false);
          message.success("Company updated succesfully");
          companyAccountForm.resetFields();
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    fetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ padding: 15, borderRadius: 7, background: "white" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #D2DCE3",
            paddingBottom: 10,
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              fontSize: 24,
              fontWeight: 600,
              fontFamily: "Matter-bold",
            }}
          >
            {acct ? (
              <>{acct.company}</>
            ) : (
              <Skeleton.Input active style={{ width: "100px" }} size="large" />
            )}
          </Typography.Title>
        </div>

        <Tabs
          tabPosition={screens.xs === true ? "top" : "left"}
          className="companySeetingTab"
        >
          <TabPane
            tab={
              <span style={{ fontSize: 18, fontFamily: "Matter-bold" }}>
                Company Details
              </span>
            }
            key="1"
          >
            <CompanyDetails />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 18, fontFamily: "Matter-bold" }}>
                User Details
              </span>
            }
            key="2"
            style={{ fontSize: 18 }}
          >
            <UserDetails />
          </TabPane>
        </Tabs>
      </div>
      <Drawer
        className="editcompany"
        width={430}
        title="Edit company details"
        placement="right"
        onClose={() => {
          setUpdateCompanyModelOpen(false);
          companyAccountForm.resetFields();
        }}
        visible={UpdateCompanyModelOpen}
      >
        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="new_account"
          className="login-data"
          onFinish={(e) => updateCompany(e)}
          form={companyAccountForm}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item
              name="company"
              style={{
                marginTop: "0px",
                marginBottom: "12px",
              }}
              label={<strong>Company name</strong>}
              rules={[
                {
                  required: true,
                  message: "Please enter the name of your company",
                },
                {
                  pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                  message: "Please enter valid company name",
                },
              ]}
            >
              <Input
                size="middle"
                id="company"
                key="company"
                name="company"
                placeholder="Company Name"
              />
            </Form.Item>
            <Form.Item
              name="url"
              label={<strong>Website</strong>}
              rules={[
                {
                  required: true,
                  message: "Please enter the URL of your company",
                },
                {
                  pattern:
                    /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                  message: "Please enter valid URL",
                },
              ]}
            >
              <Input
                size="middle"
                id="url"
                key="url"
                name="url"
                placeholder="Company Website"
                disabled
              />
            </Form.Item>
          </Space>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setUpdateCompanyModelOpen(false)}
              style={{ fontSize: 16, marginRight: 8, height: "40px" }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{ fontSize: 16, height: "40px", background: "#416B88" }}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default CompanyDetail;
