import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Typography, Button, message } from "antd";
import frame from "../../../assets/frame.svg";
import { logout } from "../../../store/action/loginAction";

function RequestPending(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  // Function to handle logout
  const logOut = async () => {
    setLoading(true);
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        dispatch(logout());
        history.push("/signin");
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to log out");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (props.accountid) {
      logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row
      justify="center"
      style={{ width: "100%", display: "flex", alignItems: "center" }}
    >
      <Col span={8} style={{ textAlign: "center" }}>
        <img src={frame} alt="request access" />
        <Typography.Title style={{ color: "#303030", fontSize: "22px" }}>
          Pending Request Approval!
        </Typography.Title>
        <Typography.Paragraph style={{ fontSize: "18px" }}>
          Your request to join team has been sent to account administrator. Once
          it is approved, you will receive an email to login to your account.
        </Typography.Paragraph>
        <Typography.Text style={{ color: "#303030", fontSize: "16px" }}>
          <strong>For any help connect via</strong>{" "}
          <span style={{ color: "#416B88", fontSize: "18px" }}>
            <a href="mailto:connect@xaaspricing.com">connect@xaaspricing.com</a>
          </span>
        </Typography.Text>
        <Button
          style={{
            color: "#FFFFFF",
            width: "50%",
            background: "#416B88",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            height: "44px",
            fontSize: "18px",
            marginTop: "20px",
          }}
          loading={loading}
          onClick={logOut}
        >
          Ok, Thank you!
        </Button>
      </Col>
    </Row>
  );
}

export default RequestPending;
