import FullPageLayout from "../containers/FullPageLayout";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

class PublicRoutes extends Component {
  componentDidMount;
  render() {
    const { token, groups, render, location, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {token && groups.length > 0 && (
              <Redirect from={matchProps.path} to="/" />
            )}
            {token && groups.length === 0 && (
              <Redirect from={matchProps.path} to="/details" />
            )}
            {!token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
  };
};

export default connect(mapStateToProps)(PublicRoutes);
