/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company
      company_loweredcased
      company_description
      segment_competitor
      segment_comapany
      url
      disabled
      users
      metadata
      invitedMembers
      acceptedInvites
      trial_end
      createdAt
      updatedAt
      industry
      company_type
      company_segment
      employee_size
      job_role
      category
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        Targets
        bulletPoints
        packageID
        stripePriceID
        Pricing
        listed
        order
        allowedDashboards
        createdAt
        updatedAt
      }
      website_score {
        items {
          id
          accountID
          overallscore
          page_design_id
          pricing_page_content_id
          ctas_and_support_id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        company_loweredcased
        company_description
        segment_competitor
        segment_comapany
        url
        disabled
        users
        metadata
        invitedMembers
        acceptedInvites
        trial_end
        createdAt
        updatedAt
        industry
        company_type
        company_segment
        employee_size
        job_role
        category
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        owners
        editors
        viewers
        requests {
          nextToken
        }
        plan {
          id
          name
          Targets
          bulletPoints
          packageID
          stripePriceID
          Pricing
          listed
          order
          allowedDashboards
          createdAt
          updatedAt
        }
        website_score {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAccountRequest = /* GraphQL */ `
  query GetAccountRequest($id: ID!) {
    getAccountRequest(id: $id) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listAccountRequests = /* GraphQL */ `
  query ListAccountRequests(
    $filter: ModelAccountRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      Targets
      bulletPoints
      packageID
      stripePriceID
      Pricing
      listed
      order
      allowedDashboards
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Targets
        bulletPoints
        packageID
        stripePriceID
        Pricing
        listed
        order
        allowedDashboards
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDashboardItems = /* GraphQL */ `
  query GetDashboardItems($id: ID!) {
    getDashboardItems(id: $id) {
      id
      description
      website_grader_description
      grade_chart
      createdAt
      updatedAt
    }
  }
`;
export const listDashboardItemss = /* GraphQL */ `
  query ListDashboardItemss(
    $filter: ModelDashboardItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardItemss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        website_grader_description
        grade_chart
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDashboardData = /* GraphQL */ `
  query GetDashboardData($id: ID!) {
    getDashboardData(id: $id) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const listDashboardDatas = /* GraphQL */ `
  query ListDashboardDatas(
    $filter: ModeldashboardDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dashboardName
        dashboardID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResearchReport = /* GraphQL */ `
  query GetResearchReport($id: ID!) {
    getResearchReport(id: $id) {
      id
      name
      key
      createdAt
      updatedAt
    }
  }
`;
export const listResearchReports = /* GraphQL */ `
  query ListResearchReports(
    $filter: ModelresearchReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResearchReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebsiteScore = /* GraphQL */ `
  query GetWebsiteScore($id: ID!) {
    getWebsiteScore(id: $id) {
      id
      accountID
      overallscore
      page_design_id
      pricing_page_content_id
      ctas_and_support_id
      note
      createdAt
      updatedAt
      page_design {
        id
        score
        design_simplicity
        plan_description
        clear_benefit
        clear_problem
        social_proof
        recommended_plan_highlighted
        at_glance_plan_comparision
        pricing_listed_one_place
        note
        createdAt
        updatedAt
      }
      pricing_page_content {
        id
        score
        published_pricing
        clear_value_metric
        clear_usage_metric
        clear_buyer_personas
        clear_feature_summary
        detailed_features_table
        monthly_or_annual_price
        volume_pricing_menu
        localized_pricing
        note
        createdAt
        updatedAt
      }
      ctas_and_support {
        id
        score
        clear_cta_buttons
        clear_free_trial_cta
        clear_free_plan_cta
        optional_contact_sales_cta
        pricing_specific_faq
        integrated_chat
        note
        createdAt
        updatedAt
      }
    }
  }
`;
export const listWebsiteScores = /* GraphQL */ `
  query ListWebsiteScores(
    $filter: ModelWebsiteScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebsiteScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        overallscore
        page_design_id
        pricing_page_content_id
        ctas_and_support_id
        note
        createdAt
        updatedAt
        page_design {
          id
          score
          design_simplicity
          plan_description
          clear_benefit
          clear_problem
          social_proof
          recommended_plan_highlighted
          at_glance_plan_comparision
          pricing_listed_one_place
          note
          createdAt
          updatedAt
        }
        pricing_page_content {
          id
          score
          published_pricing
          clear_value_metric
          clear_usage_metric
          clear_buyer_personas
          clear_feature_summary
          detailed_features_table
          monthly_or_annual_price
          volume_pricing_menu
          localized_pricing
          note
          createdAt
          updatedAt
        }
        ctas_and_support {
          id
          score
          clear_cta_buttons
          clear_free_trial_cta
          clear_free_plan_cta
          optional_contact_sales_cta
          pricing_specific_faq
          integrated_chat
          note
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPageDesign = /* GraphQL */ `
  query GetPageDesign($id: ID!) {
    getPageDesign(id: $id) {
      id
      score
      design_simplicity
      plan_description
      clear_benefit
      clear_problem
      social_proof
      recommended_plan_highlighted
      at_glance_plan_comparision
      pricing_listed_one_place
      note
      createdAt
      updatedAt
    }
  }
`;
export const listPageDesigns = /* GraphQL */ `
  query ListPageDesigns(
    $filter: ModelPageDesignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPageDesigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        score
        design_simplicity
        plan_description
        clear_benefit
        clear_problem
        social_proof
        recommended_plan_highlighted
        at_glance_plan_comparision
        pricing_listed_one_place
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPricingPageContent = /* GraphQL */ `
  query GetPricingPageContent($id: ID!) {
    getPricingPageContent(id: $id) {
      id
      score
      published_pricing
      clear_value_metric
      clear_usage_metric
      clear_buyer_personas
      clear_feature_summary
      detailed_features_table
      monthly_or_annual_price
      volume_pricing_menu
      localized_pricing
      note
      createdAt
      updatedAt
    }
  }
`;
export const listPricingPageContents = /* GraphQL */ `
  query ListPricingPageContents(
    $filter: ModelPricingPageContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricingPageContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        score
        published_pricing
        clear_value_metric
        clear_usage_metric
        clear_buyer_personas
        clear_feature_summary
        detailed_features_table
        monthly_or_annual_price
        volume_pricing_menu
        localized_pricing
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCTAsAndSupport = /* GraphQL */ `
  query GetCTAsAndSupport($id: ID!) {
    getCTAsAndSupport(id: $id) {
      id
      score
      clear_cta_buttons
      clear_free_trial_cta
      clear_free_plan_cta
      optional_contact_sales_cta
      pricing_specific_faq
      integrated_chat
      note
      createdAt
      updatedAt
    }
  }
`;
export const listCTAsAndSupports = /* GraphQL */ `
  query ListCTAsAndSupports(
    $filter: ModelCTAsAndSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCTAsAndSupports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        score
        clear_cta_buttons
        clear_free_trial_cta
        clear_free_plan_cta
        optional_contact_sales_cta
        pricing_specific_faq
        integrated_chat
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGraphDefinition = /* GraphQL */ `
  query GetGraphDefinition($id: ID!) {
    getGraphDefinition(id: $id) {
      id
      graph_type
      graphql_metric
      graphql_description
      analytics_type
      createdAt
      updatedAt
    }
  }
`;
export const listGraphDefinitions = /* GraphQL */ `
  query ListGraphDefinitions(
    $filter: ModelGraphDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphDefinitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        graph_type
        graphql_metric
        graphql_description
        analytics_type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
