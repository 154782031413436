import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Tabs,
  List,
  Tooltip,
  Button,
  Col,
  Input,
  Modal,
  Typography,
  Form,
  message,
  Select,
  Grid,
  Space,
  Skeleton,
} from "antd";
import InviteIcon from "../../../assets/InviteIcon.svg";
import upgradePlan from "../../../assets/upgradeplan.svg";
import InviteModalIcon from "../../../assets/InviteModalIcon.svg";
import Moment from "react-moment";
import sdk from "../../../sdk/Accounts";
import { useSelector } from "react-redux";
import ResetIcon from "../../../assets/reset_icon.svg";
import { emailPattern } from "../../../utility/constants";

export default function UserDetails({ reset }) {
  const history = useHistory();
  const [Users, setUsers] = useState([]);
  const [isInviteModelOpen, setIsInviteModelOpen] = useState(false);
  const [searchText, setsearchText] = useState("");
  // eslint-disable-next-line
  const [searchTextReq, setsearchTextReq] = useState("");
  const [userRequests, setuserRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Inviteloading, setInviteLoading] = useState(false);
  const { groups, accountDetails } = useSelector((state) => state.loginReducer);
  const [acct, setAcct] = useState(null);
  const [inviteData, setinviteData] = useState();
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [inviteform] = Form.useForm();
  const { useBreakpoint } = Grid;
  // eslint-disable-next-line
  const screens = useBreakpoint();
  const { Search } = Input;
  const { TabPane } = Tabs;

  const handleCancel = () => {
    setIsInviteModelOpen(false);
  };

  const get_attribute = (user, key) => {
    let result = user.filter((attribute) => attribute.Name === key);
    if (result.length > 0) {
      return result[0]["Value"];
    } else {
      return "";
    }
  };

  const setUsersData = () => {
    let userdata = acct?.users
      ?.map((item) => JSON.parse(item))
      .map((item) => ({
        ...item,
        Useremail: `${get_attribute(item?.Attributes, "email")}`,
        UserFullName: `${get_attribute(
          item?.Attributes,
          "given_name"
        )} ${get_attribute(item?.Attributes, "family_name")}`,
      }));
    setUsers(userdata);
  };
  const setUserRequestData = () => {
    let requests = acct?.requests?.items;
    requests ? setuserRequests(requests) : setuserRequests([]);
  };

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);

      setCompanyData(account);
      setLoading(false);
    }
  };

  const setCompanyData = (account) => {
    if (!account?.url?.includes("http")) {
      account.url = "https://" + account?.url;
    }
    setAcct(account);
  };
  const approveRequest = async (id) => {
    if (id) {
      setLoading(true);
      let response = await sdk.approveRequest(id);
      if (response.errors || response.error) {
        setLoading(false);
        message.error("Failed to approve request.");
        return;
      } else {
        setLoading(false);
        deleteRequest(id);
        message.success(`Approved successfully.`, 5);
      }
    }
  };

  const deleteRequest = async (id) => {
    if (id) {
      setLoading(true);
      await sdk.deleteRequest(id);
      fetchAccountDetails();
      setLoading(false);
    }
  };

  const onInvite = () => {
    let data = { account_id: acct?.id, name: acct?.company };

    setinviteData(data);
  };
  const sendInvitation = async (value) => {
    value.account_id = inviteData.account_id;

    value.name = inviteData.name;
    setInviteLoading(true);
    let result = await sdk.sendEmailInvitation(value);

    if (result.error) {
      message.error(result.error, 5);
      setInviteLoading(false);
    } else {
      fetchAccountDetails();
      setInviteLoading(false);
      inviteform.resetFields();
      message.success(`Invitation sent successfully.`, 5);
    }
    setIsInviteModelOpen(false);
  };
  const validateEmail = (e) => {
    const email_pattern = emailPattern;
    if (e.length > 0) {
      const found = e[e.length - 1].match(email_pattern);
      if (!found) {
        e.pop();
        message.error("Please enter valid email");
      } else {
        e[e.length - 1] = e[e.length - 1].toLowerCase();
      }
    }
  };

  const onInviteSend = () => {
    setInviteLoading(true);
    inviteform
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        sendInvitation(formatedValues);
      })

      .catch((info) => {
        setInviteLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  const openInviteModal = () => {
    if (
      accountDetails?.plan?.name === "Team" ||
      accountDetails?.plan?.name === "Enterprise"
    ) {
      setIsInviteModelOpen(true);
    } else {
      setUpgradeModal(true);
    }
  };

  useEffect(() => {
    setUserRequestData();
    setUsersData();
    onInvite();
    // eslint-disable-next-line
  }, [acct]);

  useEffect(() => {
    fetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchTextReq?.length === 0) setUserRequestData();
    else {
      let requestFiltered = [];
      requestFiltered = userRequests.filter((item) =>
        String(item.owner).includes(searchTextReq)
      );
      setuserRequests(requestFiltered);
    }
    // eslint-disable-next-line
  }, [searchTextReq]);

  useEffect(() => {
    if (searchText?.length === 0) setUsersData();
    else {
      let userFilterd = Users?.filter(
        (item) =>
          String(item?.UserFullName).toLowerCase().includes(searchText) ||
          String(item?.Useremail).toLowerCase().includes(searchText)
      );
      setUsers(userFilterd);
    }
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <>
      <Row style={{ padding: 10 }}>
        <Col span={16} lg={16} md={24} sm={24} xs={24}>
          <Tabs className="userTab">
            <TabPane
              tab={
                <span style={{ fontSize: 18, fontFamily: "Matter-bold" }}>
                  Users
                </span>
              }
              key="1"
              style={{ height: "68vh", overflowY: "scroll" }}
            >
              <Row style={{ display: "flex", flexWrap: "wrap" }}>
                <Col span={22} lg={22} md={22} sm={20} xs={20}>
                  <Search
                    value={searchText}
                    onChange={(event) => setsearchText(event.target.value)}
                    className="searchbar"
                    placeholder="Search Team Member"
                  />
                </Col>
                <Col
                  span={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ResetIcon}
                    alt=""
                    style={{ cursor: "pointer", paddingTop: 5, height: 30 }}
                    title="Refresh"
                    onClick={() => fetchAccountDetails()}
                  />
                </Col>
              </Row>
              {acct ? (
                <List
                  itemLayout="horizontal"
                  className="text-details"
                  dataSource={Users}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Typography.Text
                            style={{
                              fontSize: 18,
                              marginBottom: "0 !important",
                              lineHeight: "1 !important",
                            }}
                          >
                            {item.Useremail}
                          </Typography.Text>
                        }
                        description={
                          <Typography.Text style={{ fontSize: 14 }}>
                            {item.UserFullName?.length > 1
                              ? item.UserFullName
                              : "-"}
                          </Typography.Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <>
                  <Space style={{ width: "100%" }} direction="vertical">
                    <Skeleton.Input
                      active
                      style={{
                        width: "60vw",
                        height: "50px",
                        borderRadius: 7,
                        marginTop: 10,
                      }}
                      size="large"
                    />
                    <Skeleton.Input
                      active
                      style={{ width: "60vw", height: "50px", borderRadius: 7 }}
                      size="large"
                    />
                    <Skeleton.Input
                      active
                      style={{ width: "60vw", height: "50px", borderRadius: 7 }}
                      size="large"
                    />
                    <Skeleton.Input
                      active
                      style={{ width: "60vw", height: "50px", borderRadius: 7 }}
                      size="large"
                    />
                  </Space>
                </>
              )}
            </TabPane>

            <TabPane
              tab={
                <span style={{ fontSize: 18, fontFamily: "Matter-bold" }}>
                  Requests
                </span>
              }
              key="2"
              style={{ height: "68vh", overflowY: "scroll" }}
            >
              {acct ? (
                <List
                  itemLayout="horizontal"
                  dataSource={userRequests}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Tooltip title={`Approve ${item.owner}`}>
                          <Button
                            key="list-loadmore-edit"
                            style={{
                              color: "#416B88",
                              borderColor: "#416B88",
                              background: "none",
                              width: "auto",
                              height: "auto",
                              fontWeight: 600,
                              fontSize: 16,
                              padding: "4px 15px",
                            }}
                            onClick={() => approveRequest(item.id)}
                          >
                            Approve
                          </Button>
                        </Tooltip>,
                        <Tooltip title={`Deny ${item.owner}`}>
                          <Button
                            key="list-loadmore-more"
                            style={{
                              color: "#727272",
                              borderColor: "#727272",
                              background: "none",
                              height: "auto",
                              width: "auto",
                              fontWeight: 600,
                              fontSize: 16,
                              padding: "4px 15px",
                            }}
                            onClick={() => deleteRequest(item.id)}
                          >
                            Deny
                          </Button>
                        </Tooltip>,
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          <a
                            style={{ fontSize: 18 }}
                            href={`mailto:${item.owner}`}
                          >
                            {item.owner}
                          </a>
                        }
                        description={<Moment fromNow>{item.createdAt}</Moment>}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <>
                  <Space style={{ width: "100%" }} direction="vertical">
                    <Skeleton.Input
                      active
                      style={{
                        width: "60vw",
                        height: "50px",
                        borderRadius: 7,
                        marginTop: 10,
                      }}
                      size="large"
                    />
                    <Skeleton.Input
                      active
                      style={{ width: "60vw", height: "50px", borderRadius: 7 }}
                      size="large"
                    />
                    <Skeleton.Input
                      active
                      style={{ width: "60vw", height: "50px", borderRadius: 7 }}
                      size="large"
                    />
                    <Skeleton.Input
                      active
                      style={{ width: "60vw", height: "50px", borderRadius: 7 }}
                      size="large"
                    />
                  </Space>
                </>
              )}
            </TabPane>
          </Tabs>
        </Col>

        <Col span={8} lg={8} md={24} sm={24} xs={24} className="invite-user">
          <div
            className="float-chil"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="dashboard-step-header"
              style={{ paddingTop: 20, textAlign: "center" }}
            >
              <img
                className="inviteIMG"
                src={InviteIcon}
                alt=""
                style={{ height: 200, paddingLeft: 14 }}
              />
            </div>
            <div
              className="dashboard-card-header"
              style={{
                paddingTop: 10,
                paddingBottom: 30,
                paddingLeft: 10,
                fontSize: 18,
                textAlign: "center",
                fontWeight: 200,
                lineHeight: "25px",
              }}
            >
              Work collaboratively with your <br /> Team Members to bring <br />{" "}
              efficient results
            </div>
            {loading && <div className="loading">Loading;</div> ? (
              <Row>
                <Col span={8}>
                  <Skeleton.Button
                    active
                    style={{
                      borderRadius: "7px",
                      height: "44px",
                      width: "250px",
                      padding: "7px 20px",
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: 30,
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <Button
                style={{
                  marginLeft: "15%",
                  fontSize: "18px",
                  background: "#416B88",
                  height: "auto",
                  width: "auto",
                  fontWeight: "bold",
                  padding: "7px 20px",
                  fontFamily: "Matter-bold",
                }}
                className="userTab save-btn"
                type="primary"
                onClick={openInviteModal}
              >
                Invite Team Members
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        visible={isInviteModelOpen}
        closable={true}
        centered
        width={430}
        footer={[]}
        style={{ borderRadius: "20px" }}
        className="suspend-company-footer"
        onCancel={handleCancel}
      >
        <div className="delete-modal-body">
          <img
            src={InviteModalIcon}
            alt="suspend company"
            style={{ margin: "0 auto", display: "flex" }}
          />
          <Typography.Title
            style={{
              fontSize: "22px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              paddingTop: "13px",
            }}
          >
            Invite Team Members
          </Typography.Title>
          <Typography.Text
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: 16,
              display: "flex",
            }}
          >
            Share direct invitation to your team members so they can join XaaS
            Pricing with your organization account ID
          </Typography.Text>
          <Form
            layout="vertical"
            form={inviteform}
            style={{ paddingTop: "13px" }}
            requiredMark={false}
          >
            <Form.Item
              labelS
              name="emails"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Select
                className="invitemail"
                mode="tags"
                style={{ width: "100%", borderRadius: 7, fontSize: 18 }}
                tokenSeparators={[","]}
                notFoundContent=""
                onChange={validateEmail}
                placeholder={
                  <span style={{ color: "grey" }}>Enter email id</span>
                }
              >
                {/* {children} */}
              </Select>
            </Form.Item>
          </Form>
          <Button
            className="save-btn"
            style={{
              display: "flex",
              margin: "0 auto",
              width: "100%",
              height: "auto",
              background: "#416B88",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
              fontSize: "18px",
              fontWeight: "600",
              padding: "9px 12px",
            }}
            loading={Inviteloading}
            onClick={onInviteSend}
          >
            Share Invite
          </Button>
        </div>
      </Modal>

      <Modal
        className="planIntroModal"
        centered
        footer={null}
        title={null}
        visible={upgradeModal}
      >
        <div
          style={{ display: "flex", justifyContent: "center", paddingTop: 40 }}
        >
          <img src={upgradePlan} alt="" />
        </div>
        <Typography.Title
          className="font-family-style"
          style={{
            fontSize: 22,
            textAlign: "center",
            paddingTop: 15,
            fontWeight: 600,
          }}
        >
          Upgrade Your Plan!{" "}
        </Typography.Title>
        <Typography.Text
          style={{ fontSize: 16, display: "flex", justifyContent: "center" }}
        >
          Access data and insights on the pricing strategies
        </Typography.Text>
        <Typography.Text
          style={{ fontSize: 16, display: "flex", justifyContent: "center" }}
        >
          of your market and your competitors.
        </Typography.Text>
        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
          <Button
            onClick={() => history.push("/settings")}
            style={{
              height: "auto",
              background: "#416B88",
              width: "200px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              fontWeight: 600,
              fontSize: 16,
              padding: "7px 20px",
            }}
            className="font-family-style"
          >
            View Pricing Plans
          </Button>
        </div>
      </Modal>
    </>
  );
}
