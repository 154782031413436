import React from "react";
import { Button, Modal, Typography } from "antd";
import upgradePlan from "../assets/upgradeplan.svg"

function UpgradePlanModal({ visible, onContinue }) {
  return (
    <div>
        <Modal
        className="planIntroModal"
        centered
        footer={null}
        title={null}
        visible={visible}
      >
        <div
          style={{ display: "flex", justifyContent: "center", paddingTop: 40 }}
        >
          <img src={upgradePlan} alt="" />
        </div>
        <Typography.Title
          style={{ fontSize: 22, textAlign: "center", paddingTop: 15,fontWeight: 600 }}
        >
          Upgrade Your Plan!{" "}
        </Typography.Title>
        <Typography.Text
          style={{ fontSize: 16, display: "flex", justifyContent: "center" }}
        >
          Access data and insights on the pricing strategies
        </Typography.Text>
        <Typography.Text
          style={{ fontSize: 16, display: "flex", justifyContent: "center" }}
        >
         of your market and your competitors.
        </Typography.Text>
        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
          <Button
            onClick={() => {
                onContinue?.();
              }}
            style={{
              height: "auto",
              background: "#416B88",
              width: "200px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              fontWeight: 600,
              fontSize: 16,
              padding: "7px 20px",
            }}
          >
            View Pricing Plans
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default UpgradePlanModal