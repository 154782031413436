import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router";
import { message } from "antd";
import Footer from "../../../common/footer";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import Index from "../Login/background";
import { emailPattern } from "../../../utility/constants";
import pdf from "../../../assets/pdf/XaaS Pricing Privacy Policy.pdf";

function CreatePassword(props) {
  const [msg, setmsg] = useState({ text: "", type: "success" });
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userAttributes?.email : ""
  );
  const history = useHistory();

  //Function to update user's profile
  const handleUpdateProfile = (value) => {
    setloading(true);
    let formvalue = form.getFieldValue();
    let data = {
      email: formvalue.email,
      family_name: formvalue.family_name,
      given_name: formvalue.given_name,
    };
    let init = {
      body: {
        ...data,
      },
    };
    API.post("XaaSPricingREST", `/user/update`, init)
      .then((data) => {
        message.success("Profile Updated");
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      });
  };

  // Function to change password
  const confirmChangePassoword = (values) => {
    setloading(true);
    let oldPassword = props.location?.state?.oldPassword;
    if (oldPassword) {
      let newPassword = form.getFieldValue("password");
      Auth.signIn(email, oldPassword).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newPassword, [])
            .then(async (user) => {
              delete values.password;
              handleUpdateProfile(values);
              message.success("Login with new password.");
              history.push("/");
            })
            .catch((err) => {
              console.log(err);
              setmsg({ text: err, type: "error" });
            });
        }
      });
    }
  };

  useEffect(() => {
    if (props.location?.state) {
      let email = props.location?.state?.userAttributes?.email;
      if (email) {
        setemail(email);
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Row justify="center">
      <Index />
      <Col span={12} md={12} lg={12} xs={24}>
        <Row
          justify="center"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          <Col span={12} style={{ textAlign: "left" }}>
            <img
              src={Xaaslogo}
              alt=""
              style={{ display: "flex", margin: "0 auto 20px", height: 100 }}
            />
            {msg?.text && (
              <Alert
                style={{ marginBottom: "10px" }}
                type={msg?.type}
                showIcon={false}
                message={msg.text}
                banner
              />
            )}
            <Form
              requiredMark={false}
              layout="vertical"
              name="normal_reset"
              className="login-form"
              onFinish={(e) => confirmChangePassoword(e)}
              autoComplete="off"
              form={form}
            >
              <Row
                style={{ display: "flex", justifyContent: "space-between" }}
                gutter={10}
              >
                <Col span={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: 18, fontWeight: 400 }}>
                        First Name
                      </span>
                    }
                    name="given_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                      {
                        pattern: /^[a-zA-Z]+$/,
                        message: "symbol, number or whitespace are not allowed",
                      },
                    ]}
                  >
                    <Input
                      id="firstName"
                      key="firstName"
                      name="firstName"
                      placeholder="Your first name"
                      style={{
                        borderRadius: "8px",
                        height: "44px",
                        fontSize: 18,
                      }}
                      disabled={loading}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: 18, fontWeight: 400 }}>
                        Last Name
                      </span>
                    }
                    name="family_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                      {
                        pattern: /^[a-zA-Z]+$/,
                        message: "symbol, number or whitespace are not allowed",
                      },
                    ]}
                  >
                    <Input
                      id="lastName"
                      key="lastName"
                      name="lastName"
                      placeholder="Your last name"
                      style={{
                        borderRadius: "8px",
                        height: "44px",
                        fontSize: 18,
                      }}
                      disabled={loading}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={
                  <span style={{ fontSize: 18, fontWeight: 400 }}>Email</span>
                }
                initialValue={email}
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                  {
                    pattern: emailPattern,
                    message: "Please provide valid Email!",
                  },
                ]}
              >
                <Input
                  disabled
                  autoFocus
                  value={email}
                  id="userName"
                  key="userName"
                  name="userName"
                  placeholder="Your email id"
                  style={{
                    borderRadius: "8px",
                    height: "44px",
                    fontSize: 18,
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: 18, fontWeight: 400 }}>
                    Create Password
                  </span>
                }
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  id="password"
                  key="password"
                  name="password"
                  type="password"
                  autocomplete="new-password"
                  placeholder="Your password"
                  style={{
                    borderRadius: "8px",
                    height: "44px",
                    fontSize: 18,
                  }}
                  disabled={loading}
                />
              </Form.Item>
              <div
                style={{ paddingBottom: 20, textAlign: "left", fontSize: 14 }}
              >
                By registering, you agree to the processing of your personal
                data by XaaS Pricing as described in our{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={pdf}
                  style={{ fontSize: 14, fontWeight: "bold", color: "#799FB9" }}
                >
                  Privacy Policy
                </a>
              </div>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Button
                    type="link"
                    onClick={() => history.push("/signin")}
                    disabled={loading}
                    style={{ paddingLeft: 0 }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center",
                        color: "#416B88",
                        height: "auto",
                      }}
                    >
                      Login
                    </span>
                  </Button>
                  <Button
                    size="large"
                    htmlType="submit"
                    block
                    tabIndex={5}
                    style={{
                      background: "#416B88",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      height: "44px",
                      marginBottom: 0,
                      fontSize: 18,
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                    loading={loading}
                  >
                    <span>Sign Up</span>
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
}
export default CreatePassword;
