import React from "react";

function UserTab() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
      }}
    >
      <h1 style={{ color: "#416b88", fontWeight: 600, fontSize: 30 }}>
        Coming Soon
      </h1>
    </div>
  );
}

export default UserTab;
