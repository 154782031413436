import { Auth } from "aws-amplify";
import {
  LOGOUT,
  UPDATE_LOGIN,
  SET_PERMISSION,
  SET_ACCOUNT_DETAILS,
  SET_ACCOUNT_REQUESTS,
  UPDATE_COMPANY_ACCOUNT,
  SET_VIEW_TYPE,
  SET_DROPDOWN,
  SET_AUTHOR,
  SET_USER_NAME,
  SET_SUBSCRIPTION_DETAILS,
  SET_FIRSTTIMEUSER,
  SET_GROUP_VALUE,
  SET_COMPANY_NAME
} from "./index";
import { XassApi } from "../../utility/Api";
import sdk from "../../sdk/Accounts";

export const login = (userName, password) => {
  return async (dispatch, getState) => {
    let response = await Auth.signIn(userName, password);
    localStorage.setItem("given_name", response?.attributes?.given_name);
    if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
      return response;
    }
    const { idToken } = await Auth.currentSession();
    dispatch(setPermission(idToken.payload));
    let userAccountId = [];

    if ("cognito:groups" in response.signInUserSession.accessToken.payload) {
      userAccountId =
        response.signInUserSession.accessToken.payload["cognito:groups"];
      dispatch(setGroupvalues(userAccountId));

      userAccountId.push(
        userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]
      );
      userAccountId.push(
        userAccountId.splice(userAccountId.indexOf("author"), 1)[0]
      );
    }

    let res = await sdk.fetchAccount(userAccountId[0]);
    dispatch(setAccountDetails(res));

    dispatch(setLoginData(response.signInUserSession, response.attributes));
    return response;
  };
};

export const getCustomerAccountRequests = () => (dispatch) => {
  XassApi.getAccountRequests()
    .then((response) => {
      dispatch(setAccountRequests(response.data?.listAccountRequests?.items));
    })
    .catch((e) => console.log(e));
};

export const getaccountDetails = (inputdata) => (dispatch) => {
  XassApi.getAccointApi(inputdata)
    .then((response) => {
      dispatch(setAccountDetails(response.data.getAccount));
    })
    .catch((err) => console.log(err));
};

export const setLoginData = (token, userData) => {
  return {
    type: UPDATE_LOGIN,
    payload: token,
    data: userData,
  };
};

export const setPermission = (data) => {
  return {
    type: SET_PERMISSION,
    payload: data,
  };
};

export const setAccountDetails = (data) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: data,
  };
};

export const setGroupvalues = (data) => {
  return {
    type: SET_GROUP_VALUE,
    payload: data,
  };
};

export const setAccountRequests = (inputdata) => {
  return {
    type: SET_ACCOUNT_REQUESTS,
    payload: inputdata,
  };
};
export const updateCompanyAccount = (data) => {
  return {
    type: UPDATE_COMPANY_ACCOUNT,
    payload: data,
  };
};
export const setViewType = (data) => {
  return {
    type: SET_VIEW_TYPE,
    payload: data,
  };
};
export const setDropdown = (data) => {
  return {
    type: SET_DROPDOWN,
    payload: data,
  };
};
export const setAuthor = (data) => {
  return {
    type: SET_AUTHOR,
    payload: data,
  };
};
export const setUserName = (data) => {
  return {
    type: SET_USER_NAME,
    payload: data,
  };
};
export const setSubscriptionDetials = (data) => {
  return {
    type: SET_SUBSCRIPTION_DETAILS,
    payload: data,
  };
};
export const setFirstTimeUser = (data) => {
  return {
    type: SET_FIRSTTIMEUSER,
    payload: data,
  };
};
export const setCompanyName = (data) => {
  return {
    type: SET_COMPANY_NAME,
    payload: data,
  };
};

export const getPlanList = () => async (dispatch, getState) => {
  let nextToken = null;
  let list = [];
  do {
    let res = await XassApi.getPlanListApi();
    res?.data?.listPlans?.items?.map((item) => list.push(item));
    nextToken = res?.data?.listPlans?.nextToken;
  } while (nextToken);
  return list;
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
