import { API } from "aws-amplify";
import {
  createCTAsAndSupport,
  createPageDesign,
  createPricingPageContent,
  createResearchReport,
  createWebsiteScore,
  deleteCTAsAndSupport,
  deletePageDesign,
  deletePricingPageContent,
  deleteResearchReport,
  deleteWebsiteScore,
  updateCTAsAndSupport,
  updatePageDesign,
  updatePricingPageContent,
  updateWebsiteScore,
} from "../graphql/mutations";
import {
  listAccountRequests,
  listPlans,
  listResearchReports,
  listWebsiteScores,
} from "../graphql/queries";

const getAccountRequests = () => {
  return API.graphql({ query: listAccountRequests });
};

const getPlanListApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listPlans,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const fetchReportsAPI = () => {
  return API.graphql({
    query: listResearchReports,
    variables: {},
  });
};

const createReportAPI = (inputData) => {
  return API.graphql({
    query: createResearchReport,
    variables: { input: inputData },
  });
};

const deleteReportAPI = (inputData) => {
  return API.graphql({
    query: deleteResearchReport,
    variables: { input: inputData },
  });
};

const fetchWebsiteScore = (filter, nextToken) => {
  return API.graphql({
    query: listWebsiteScores,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const createUpdateWebsiteGrade = (inputData) => {
  return API.graphql({
    query: inputData?.id ? updateWebsiteScore : createWebsiteScore,
    variables: { input: inputData },
  });
};
const createUpdatePageDesign = (inputData) => {
  return API.graphql({
    query: inputData?.id ? updatePageDesign : createPageDesign,
    variables: { input: inputData },
  });
};
const createUpdatePricingPageContent = (inputData) => {
  return API.graphql({
    query: inputData?.id ? updatePricingPageContent : createPricingPageContent,
    variables: { input: inputData },
  });
};
const createUpdateCTAsAndSupport = (inputData) => {
  return API.graphql({
    query: inputData?.id ? updateCTAsAndSupport : createCTAsAndSupport,
    variables: { input: inputData },
  });
};

const deleteWebsiteScoreAPI = (inputData) => {
  return API.graphql({
    query: deleteWebsiteScore,
    variables: { input: inputData },
  });
};

const deletePageDesignAPI = (inputData) => {
  return API.graphql({
    query: deletePageDesign,
    variables: { input: inputData },
  });
};

const deletePricingPageContentAPI = (inputData) => {
  return API.graphql({
    query: deletePricingPageContent,
    variables: { input: inputData },
  });
};

const deleteCTAsAndSupportAPI = (inputData) => {
  return API.graphql({
    query: deleteCTAsAndSupport,
    variables: { input: inputData },
  });
};

export const XassApi = {
  getAccountRequests,
  getPlanListApi,
  fetchReportsAPI,
  createReportAPI,
  deleteReportAPI,
  fetchWebsiteScore,
  createUpdateWebsiteGrade,
  createUpdateCTAsAndSupport,
  createUpdatePageDesign,
  createUpdatePricingPageContent,
  deleteWebsiteScoreAPI,
  deletePageDesignAPI,
  deletePricingPageContentAPI,
  deleteCTAsAndSupportAPI,
};
