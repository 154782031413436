import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu } from "antd";
import { useSelector , useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import {
  logout,
 
} from "../store/action/loginAction";
const DropDownMenu = (props) => {
  const { path } = props;
  const {groups} = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch()
  const [selectedKey, setselectedKey] = useState("");
  let history = useHistory();
  // Function to handle signout

  const handleSignOut = async (props) => {

    dispatch(logout());
    Auth.signOut({
      global: true,
    })
      .then((data) => {
      
          localStorage.clear();
          history.push("/signin");


      })
      .catch((err) => console.log(err));
  };
  // const handleSignOut = async () => {
  //   Auth.signOut()
  //     .then((data) => {
  //       history.push("/signin");
  //       localStorage.clear();
  //     })
  //     .catch((err) => {
  //       if (err?.code === "NotAuthorizedException") {
  //         localStorage.clear();
  //       }
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    const menukeys = ["/profile", "/admin", "/company"];
    if (menukeys.includes(path)) {
      setselectedKey(path?.split("/")[1]);
    } else {
      setselectedKey("");
    }
  }, [path]);
  return (
    <Menu
      style={{
        width: "200px",
        boxShadow:
          "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
        padding: "12px 4px",
        borderRadius: 7,
        zIndex: 9999
      }}
      selectedKeys={[selectedKey]}
      className="dropdown-title"
    >
      <Menu.Item key="profile">
        <Link to={"/myprofile"}>My Profile</Link>
      </Menu.Item>
      {!groups.includes("individual") && (
      <Menu.Item key="company">
        <Link to={"/company"}>Company Settings</Link>
      </Menu.Item>
      )}
      {groups.includes("admin") && (
        <Menu.Item key="admin">
          <Link to={"/admin"}>Super Admin Settings</Link>
        </Menu.Item>
      )}
      <Menu.Item key="logout">
        <Link to="" onClick={handleSignOut}>
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default DropDownMenu;
