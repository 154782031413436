// import external modules
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import FullPageLayout from "../containers/FullPageLayout";
import { message } from "antd";

class AccountLayoutRoute extends Component {
  componentDidMount() {
    if (!this.props.token && this.props?.location?.pathname !== "/setup") {
      message.warning("Please login");
    }
    if (!this.props.token && this.props?.location?.pathname === "/setup") {
      if (this.props?.location?.search?.length > 0) {
        localStorage.setItem(
          "accountReqId",
          this.props?.location?.search?.split("requestid=")[1]
        );
      }
    }
  }
  render() {
    const { token, groups, accountDetails, render, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {!token && <Redirect from={matchProps.path} to={"/signin"} />}

            {token && groups.length > 0 && (
              <Redirect from={matchProps.path} to="/" />
            )}
            {token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </Fragment>
        )}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    accountDetails: state.loginReducer.accountDetails,
  };
};

export default connect(mapStateToProps)(AccountLayoutRoute);
