import * as actions from "../action/index";

const initialState = {
  token: null,
  roles: [],
  userData: {},
  admin_groups_self: [],
  selectedCompanyName: null,
  accountDetails: {},
  userPermission: "",
  viewType: "",
  groupValues: [],
  accountRequests: [],
  dashboardTourOpen: false,
  selectDropDown: "",
  superAuthorAccess: true,
  userName: "",
  subscriptionDetials: {},
  firstTimeUser: false,
  isIndividual: false,
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LOGIN:
      let userData = { ...action.data };
      let groupValue = [];
      if ("cognito:groups" in action.payload.accessToken.payload) {
        groupValue = action.payload.accessToken.payload["cognito:groups"];
        groupValue.push(groupValue.splice(groupValue.indexOf("admin"), 1)[0]);
        groupValue.push(groupValue.splice(groupValue.indexOf("author"), 1)[0]);
        groupValue.push(
          groupValue.splice(groupValue.indexOf("individual"), 1)[0]
        );

        if (groupValue?.includes("stage")) {
          groupValue.splice(groupValue.indexOf("stage"), 1);
          groupValue.push("stage");
        }
      }
      return {
        ...state,
        userName: userData?.given_name,
        token: action.payload.accessToken.jwtToken,
        groups: groupValue,
        admin_groups_self: groupValue,
        userData: { ...userData },
      };

    case actions.UPDATE_COMPANY_ACCOUNT:
      const index = 0;
      const replacementItem = action.payload.id;
      let companyName = action.payload.company;
      let updatedAccount = Object.assign([], state.groups, {
        [index]: replacementItem,
      });
      if (state.selectedCompanyName?.[0] === state.groups?.[0])
        companyName = "";
      return {
        ...state,
        token: state.token,
        groups: updatedAccount,
        selectedCompanyName: companyName,
        userData: { ...state.userData },
      };

    case actions.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload,
      };
    case actions.SET_GROUP_VALUE:
      return {
        ...state,
        groupValues: action.payload,
      };
    case actions.SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };

    case actions.SET_PERMISSION:
      return {
        ...state,
        userPermission: action.payload,
      };

    case actions.SET_ACCOUNT_REQUESTS:
      return {
        ...state,
        accountRequests: action.payload,
      };

    case actions.SET_DROPDOWN:
      return {
        ...state,
        selectDropDown: action.payload,
      };
    case actions.SET_AUTHOR:
      return {
        ...state,
        superAuthorAccess: action.payload,
      };
    case actions.SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case action.SET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionDetials: action.payload,
      };
    case actions.SET_FIRSTTIMEUSER:
      return {
        ...state,
        firstTimeUser: action.payload,
      };

    case actions.SET_COMPANY_NAME:
      return {
        ...state,
        selectedCompanyName: action.payload,
      };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
