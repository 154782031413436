import React, { useState } from "react";
import { Row, Col, Button, message } from "antd";
import sdk from "../../../sdk/Accounts";
import "../user.css";
import { Auth } from "aws-amplify";
import {
  setAccountDetails,
  setLoginData,
  setFirstTimeUser,
} from "../../../store/action/loginAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

function IndividualSignin() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setIsLoading(true);
    await sdk
      .createAccount("isIndividual")
      .then(async (res) => {
        if (res.error) {
          message.error(res.error, 5);
          setIsLoading(false);
        } else {
          message.success(`Account Created Successfully`, 5);
          history.push("/details");
          dispatch(setFirstTimeUser(true));
          await fetchUser();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  // Function to fetch user account
  const fetchUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      async (err, session) => {
        const response = Auth.currentUserInfo();
        let groups = session?.accessToken?.payload?.["cognito:groups"];
        if (groups?.length > 0) {
          let res = await sdk.fetchAccount(groups[0]);
          dispatch(setAccountDetails(res));
        }
        response.then((data) => {
          dispatch(setLoginData(session, data?.attributes));
        });
      }
    );
  };
  return (
    <div>
      <Row justify="center">
        <Col
          span={12}
          offset={6}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
            fontSize: 20,
          }}
        >
          <Button
            onClick={handleSubmit}
            type="text"
            className="signup-btn"
            loading={isLoading}
          >
            Or Continue setting up Individually
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default IndividualSignin;
