import React from "react";
import { useHistory } from "react-router";
import { Button, Space, Typography } from "antd";
import thankyouImg from "../../assets/thank-you-icon.svg";

function PlanSelected(props) {
  const history = useHistory();
  return (
    <div className="thankyou-page">
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          display: "flex",
          paddingBottom: 40,
        }}
      ></div>

      <div className="thankyou-modal">
        <Space
          direction="vertical"
          align="center"
          size={10}
          style={{
            paddingBottom: 20,
            paddingTop: 20,
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <img src={thankyouImg} alt="" />
          <Typography.Text strong style={{ fontSize: 20 }}>
            Thank you!
          </Typography.Text>
          <div style={{ textAlign: "center" }}>
            <Typography.Text style={{ fontSize: 16, textAlign: "center" }}>
              Your subscription has been completed and <br />
              your XaaSPricing account is ready for use.
            </Typography.Text>
          </div>
          <Button
            size="large"
            type="primary"
            className="btn-primary"
            onClick={() => {
              history.push("/");
            }}
            style={{
              backgroundColor: "#416B88",
              color: "white",
              borderRadius: "8px",
              height: "44px",
              marginBottom: 0,
              border: 0,
              fontWeight: "bold",
              letterSpacing: ".75px",
            }}
          >
            Get Started
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default PlanSelected;
