import { XassApi } from "../../utility/Api";

export const fetchReports = () => async () => {
  return XassApi.fetchReportsAPI()
    .then((res) => res?.data?.listResearchReports?.items)
    .catch((err) => err);
};

export const createReport = (inputData) => async () => {
  return XassApi.createReportAPI(inputData);
};

export const deleteResearchReport = (inputData) => async () => {
  return XassApi.deleteReportAPI(inputData);
};
export const createUpdatePageDesignAPI = (inputData) => async () => {
  return XassApi.createUpdatePageDesign(inputData)?.then((res) => {
    let field = inputData?.id ? "updatePageDesign" : "createPageDesign";
    return res?.data?.[field];
  });
};
export const createUpdateCTAsAndSupportAPI = (inputData) => async () => {
  return XassApi.createUpdateCTAsAndSupport(inputData)?.then((res) => {
    let field = inputData?.id ? "updateCTAsAndSupport" : "createCTAsAndSupport";
    return res?.data?.[field];
  });
};
export const createUpdatePricingPageContentAPI = (inputData) => async () => {
  return XassApi.createUpdatePricingPageContent(inputData)?.then((res) => {
    let field = inputData?.id
      ? "updatePricingPageContent"
      : "createPricingPageContent";
    return res?.data?.[field];
  });
};

export const createUpdateWebsiteGrade = (inputData) => () => {
    return XassApi.createUpdateWebsiteGrade(inputData)
}

export const deleteWebsiteScore = (inputData) => async () => {
    if(inputData?.page_design_id){
        await XassApi.deletePageDesignAPI({id:inputData?.page_design_id})
    }
    if(inputData?.ctas_and_support_id){
        await XassApi.deleteCTAsAndSupportAPI({id:inputData?.ctas_and_support_id})
    }
    if(inputData?.pricing_page_content_id){
        await XassApi.deletePricingPageContentAPI({id:inputData?.pricing_page_content_id})
    }
    let res = await XassApi.deleteWebsiteScoreAPI({id:inputData?.id})
    return res
}
