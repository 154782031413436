import React from "react";
import { Tabs } from "antd";
import Companies from "./Companies";
import Users from "./Users";
import { useDispatch } from "react-redux";
import {
  updateCompanyAccount,
  setAccountDetails,
} from "../../store/action/loginAction";
import ManageData from "./ManageData";
import sdk from "../../sdk/Accounts";

const Admin = () => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();

  const selectCompany = async (accountId, from) => {
    if (from === "user") {
      dispatch(updateCompanyAccount(accountId?.Company_Data));
    } else {
      dispatch(updateCompanyAccount(accountId));
      let res = await sdk.fetchAccount(accountId?.id);
      dispatch(setAccountDetails(res));
    }

    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  };
  return (
    <div className="sub-container">
      <Tabs className="tabfont" centered>
        <TabPane
          tab={
            <h3
              className="font-family-style"
              style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
            >
              Companies
            </h3>
          }
          key="1"
        >
          <Companies selectCompany={selectCompany} />
        </TabPane>
        <TabPane
          tab={
            <h3
              className="font-family-style"
              style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
            >
              Users
            </h3>
          }
          key="2"
        >
          <Users selectCompany={selectCompany} />
        </TabPane>
        <TabPane
          tab={
            <h3
              className="font-family-style"
              style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
            >
              Manage Data
            </h3>
          }
          key="3"
        >
          <ManageData />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Admin;
