import React from "react";
import moment from "moment";


function Footer() {
  return (
    <div
      className="footer"
      style={{
        color: "#727272",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: 400,
        position: "fixed",
        bottom: "20px",
        letterSpacing: "0.7px",
      }}
    >
      Version{" "}
      {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "1.0"}{" "}
      ©{`${moment().year()}`} XaaS Pricing.All rights reserved.
      <a
        style={{ color: "#799FB9", fontWeight: 600 }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.xaaspricing.com/terms-of-use/"
      >
        Terms of Service
      </a>
    </div>
  );
}
export default Footer;
